import { Alert, Box, Tab, Tabs, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { TabPanelProps } from '../../../../utils/interfaces/ComponentModels'
import { DDI } from '../../../../utils/interfaces/DBModels'
import TrunkOverviewDisplay from './TrunkOverviewDisplay'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { Roles } from '../../../../utils/enums/enums'
import PartnerOrderOverview from '../OrderOverviews/PartnerOrderOverview/PartnerOrderOverview'
import DDIManagement from '../../../../pages/dashboard/DDIManagement/DDIManagement'

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && <Box sx={{ padding: '30px 0' }}>{children}</Box>}
		</div>
	)
}

function a11yProps(index: number) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	}
}

const OverviewTabDisplay = ({
	customerID,
	hasPOCOrder,
	ddiList,
	onDeleteDDIs,
}: {
	customerID: string
	hasPOCOrder: boolean
	ddiList: DDI[]
	onDeleteDDIs: any
}) => {
	const theme = useTheme()
	const [value, setValue] = useState(0)

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
	}

	const [customerHasPOC] = useState(hasPOCOrder)
	const loggedInRoleAndPermissions = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)

	return (
		<>
			<div className='customer-overview-tabbed-display'>
				{loggedInRoleAndPermissions.find(
					(rolePermission) =>
						Number(rolePermission.RoleID) === Roles.PartnerAdmin
				) &&
					customerHasPOC && (
						<Alert severity='info' sx={{ width: 1000, marginTop: '20px' }}>
							This customer currently has a trial order in place, which means
							that TNs and trunks cannot be updated at this time.
						</Alert>
					)}
				<Tabs value={value} onChange={handleChange} color='text.header'>
					<Tab
						className='map-list-button'
						label='Orders'
						iconPosition='start'
						{...a11yProps(0)}
					/>
					<Tab
						className='map-list-button'
						label='TNs'
						iconPosition='start'
						{...a11yProps(1)}
					/>
					{/* only display trunks tab if customer doesn't have a poc order */}
					{!customerHasPOC && (
						<Tab label='Trunks' iconPosition='start' {...a11yProps(2)} />
					)}
				</Tabs>
				<TabPanel value={value} index={0} dir={theme.direction}>
					<PartnerOrderOverview />
				</TabPanel>
				<TabPanel value={value} index={1} dir={theme.direction}>
					<DDIManagement hideHeader={true} />
				</TabPanel>
				<TabPanel value={value} index={2} dir={theme.direction}>
					{/* Trunk Overview */}
					<TrunkOverviewDisplay customerID={customerID} />
				</TabPanel>
			</div>
		</>
	)
}

export default OverviewTabDisplay
