import { Box, MenuItem, Typography } from '@mui/material'
import './ManageAddress.scss'
import { LoadingButton } from '@mui/lab'
import { StyledTextBox } from '../../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import { StyledSelect } from '../../../../shared/styledComponents/StyledSelect/StyledSelect'
import {
	Address,
	AddressMap,
	Country,
	State,
} from '../../../../../utils/interfaces/DBModels'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { AddressTypes, ReturnTypes } from '../../../../../utils/enums/enums'
import { AddressType } from '../../../../../utils/interfaces/ComponentModels'
import {
	isEmptyOrWhitespace,
	objectToStringForGet,
	showErrorToast,
	timeout,
	validateField,
} from '../../../../../utils/helperFunctions/helperFunctions'
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined'
import { DataResponse } from '../../../../../utils/interfaces/APIModels'
import UseCrud from '../../../../../utils/customHooks/APICalls/UseCrud'
const ManageAddress = ({
	stateList,
	countryList,
	editAddress,
	selectedRow,
	getAddresses,
	clearSelectedRow,
}: {
	stateList: State[]
	countryList: Country[]
	editAddress: boolean
	selectedRow: any
	getAddresses: any
	clearSelectedRow: any
}) => {
	var loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	// Hooks
	const { fetchData, modifyData } = UseCrud()

	const [addressName, setAddressName] = useState('')
	const [houseNumber, setHouseNumber] = useState('')
	const [addressLine1, setAddressLine1] = useState('')
	const [addressLine2, setAddressLine2] = useState('')
	const [city, setCity] = useState('')
	const [postalCode, setPostalCode] = useState('')

	const [selectedAddressTypeID, setSelectedAddressTypeID] = useState(0)
	const [selectedCountryID, setSelectedCountryID] = useState(0)
	const [selectedStateID, setSelectedStateID] = useState(0)

	const [isLoading, setIsLoading] = useState(false)

	//lists
	const [addressTypeList, setAddressTypeList] = useState<AddressType[]>([])
	const [stateListDisplay, setStateDisplayList] = useState([] as State[])
	const [countryListDisplay, setCountryDisplayList] = useState([] as Country[])

	// errorMessages
	const [addressNameErrMsg, setAddressNameErrMsg] = useState('')
	const [houseNumberErrMsg, setHouseNumberErrMsg] = useState('')
	const [addressLine1ErrMsg, setAddressLine1ErrMsg] = useState('')
	const [addressLine2ErrMsg, setAddressLine2ErrMsg] = useState('')
	const [cityErrMsg, setCityErrMsg] = useState('')
	const [postalCodeErrMsg, setPostalCodeErrMsg] = useState('')

	useEffect(() => {
		var _addressTypes: AddressType[] = []

		for (var addressType in AddressTypes) {
			if (!isNaN(Number(addressType))) {
				_addressTypes.push({
					AddressTypeID: Number(addressType),
					AddressTypeName: AddressTypes[addressType],
				})
			}
		}

		// Sort Address, Country and State Lists
		setAddressTypeList(
			_addressTypes.sort((a, b) =>
				a.AddressTypeName!.localeCompare(b.AddressTypeName!, undefined, {
					sensitivity: 'base',
				})
			)
		)

		const _countryList = countryList.sort((a, b) =>
			a.CountryName!.localeCompare(b.CountryName!, undefined, {
				sensitivity: 'base',
			})
		)

		setCountryDisplayList(_countryList)
		setStateDisplayList(handleStateFilter(Number(_countryList[0].CountryID)))

		if (selectedRow === null) {
			// Clear Form & initialize (addressType, Country and State)
			clearForm(_addressTypes)
		} else {
			// Initialize form with selected Customer Address
			setAddressName(selectedRow?.AddressName)
			setHouseNumber(selectedRow?.HouseNumber)
			setAddressLine1(selectedRow?.AddressLine1)
			setAddressLine2(selectedRow?.AddressLine2)

			var selectedAddress = _addressTypes.find(
				(address: AddressType) =>
					address.AddressTypeName ===
					(selectedRow?.AddressType === 'Emergency'
						? 'EmergencyAddress'
						: selectedRow?.AddressType)
			)

			setSelectedAddressTypeID(Number(selectedAddress?.AddressTypeID))
			setCity(selectedRow?.City)

			const selectedCountry = countryList.find(
				(country: Country) => country.CountryName === selectedRow.Country
			)

			setSelectedCountryID(Number(selectedCountry?.CountryID))

			const selectedState = stateList.find(
				(state: State) => state.StateName === selectedRow.State
			)

			setSelectedStateID(Number(selectedState?.StateID))

			setStateDisplayList(handleStateFilter(Number(selectedCountry?.CountryID)))
			setPostalCode(selectedRow?.PostalCode)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryList, stateList, selectedRow])

	// API Calls: Add, Update Address

	const addAddressMap = async (addressRequestURL: string) => {
		var addressMapObj: AddressMap = {}
		var addressMapRequest: any = {}
		var postSuccess

		if (editAddress === true) {
			//update address map
			addressMapObj = {
				AddressMapID: selectedRow.AddressMapID,
				AddressID: selectedRow.AddressID,
				CarrierID: 1,
				CustomerID: loggedInUser.customerID,
				AddressName: addressName,
			}

			addressMapRequest = {
				AddressMap: addressMapObj,
			}

			//post to DB
			postSuccess = await modifyData({
				FileAndFunctionName: 'ManageAddress.tsx addAddressMap()',
				QueryURL: 'UpdateV2?Params=AddressMap',
				QueryObj: addressMapRequest,
				ErrorMessage: 'Failed to update address',
				ShowErrorMessage: false,
				ShowSuccessMessage: true,
				SuccessMessage: 'Address updated successfully',
				LogErrorToDB: false,
				UserName: loggedInUser.email,
			})

			if (postSuccess) {
				timeout(3000)
				getAddresses()
				clearForm(addressTypeList)
				clearSelectedRow()
			} else {
				showErrorToast('An error occurred when trying to update Address')
			}
		} else {
			// Add AddressMap
			var requestURL = `Address.Where(${addressRequestURL}).Pagination(NumberOfRows = '1' & PageNumber = '1' & Column = 'Address.AddressID' & SortType = 'DESC')`
			console.log(requestURL)
			var dataResponse = (await fetchData({
				FileAndFunctionName: 'ManageAddress.tsx: addAddressMap()',
				QueryURL: requestURL,
				ErrorMessage: `An error occurred while fetching address map data(URL: ${requestURL})`,
				ShowErrorToast: true,
				LogErrorToDB: true,
				ReturnType: ReturnTypes.ObjectOrList,
			})) as DataResponse

			if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
				var addressID1 = dataResponse.Obj.AddressList[0].AddressID

				addressMapObj = {
					AddressID: addressID1,
					CarrierID: 1,
					CustomerID: loggedInUser.customerID,
					AddressName: addressName,
				}

				addressMapRequest = {
					AddressMap: addressMapObj,
				}

				// Post to DB
				postSuccess = await modifyData({
					FileAndFunctionName: 'ManageAddress.tsx addAddressMap()',
					QueryURL: `AddV2?Params=AddressMap`,
					QueryObj: addressMapRequest,
					ErrorMessage: 'Failed to add AddressMap',
					ShowErrorMessage: true,
					ShowSuccessMessage: true,
					SuccessMessage: 'Address added successfully',
					LogErrorToDB: false,
					UserName: loggedInUser.email,
				})

				if (postSuccess) {
					// Pause a bit before fetching data from DB
					timeout(2000)
					getAddresses()
					clearForm(addressTypeList)
					clearSelectedRow()
				} else {
					showErrorToast('An error occurred when trying to update Address')
				}
			}
		}
	}

	// Handle functions
	const handleAddressName = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setAddressName(newValue)
		setAddressNameErrMsg(validateField('Address Name', newValue, false))
	}

	const handleHouseNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setHouseNumber(newValue)

		setHouseNumberErrMsg(validateField('House Number', newValue, true))
	}

	const handleAddressLine1 = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setAddressLine1(newValue)
		setAddressLine1ErrMsg(validateField('Address Line 1', newValue, false))
	}

	const handleAddressLine2 = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setAddressLine2(newValue)
		setAddressLine2ErrMsg(validateField('Address Line 2', newValue, true))
	}

	const handleAddressType = (e: { target: any }) => {
		var newValue = e.target.value
		setSelectedAddressTypeID(Number(newValue))
	}

	const handleCity = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setCity(newValue)
		setCityErrMsg(validateField('City', newValue, true))
	}

	const handleState = (e: { target: any }) => {
		var newValue = e.target.value
		setSelectedStateID(newValue)
	}

	const handleCountry = (e: { target: any }) => {
		var newValue = e.target.value
		setSelectedCountryID(Number(newValue))

		var filterStateList = handleStateFilter(Number(newValue))
		setStateDisplayList(filterStateList)
		setSelectedStateID(Number(filterStateList[0]?.StateID))
	}

	const handlePostalCode = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setPostalCode(newValue)
		setPostalCodeErrMsg(validateField('Postal Code', newValue, true))
	}

	const addAddress = async (e: { target: any }) => {
		// Show loading indicator
		setIsLoading(true)
		var addressObj: Address = {}
		var dataResponse
		var addressRequestObj: any = {}
		var addressRequestURL = ''

		if (editAddress === true) {
			// Update existing Address
			addressObj = {
				AddressID: selectedRow.AddressID,
				AddressTypeID: selectedAddressTypeID,
				HouseNumber: houseNumber?.trim(),
				AddressLine1: addressLine1.trim(),
				AddressLine2: addressLine2?.trim(),
				City: city,
				StateID: selectedStateID,
				PostalCode: postalCode?.trim(),
			}

			addressRequestObj = {
				Address: addressObj,
			}

			dataResponse = (await modifyData({
				FileAndFunctionName: 'ManageAddress.tsx addAddress()',
				QueryURL: `UpdateV2?Params=Address`,
				QueryObj: addressRequestObj,
				ErrorMessage: 'Could not update address',
				ShowErrorMessage: true,
				ShowSuccessMessage: false,
				SuccessMessage: 'Address updated successfully',
				LogErrorToDB: false,
				UserName: loggedInUser.email,
			})) as Boolean

			if (dataResponse) {
				if (selectedRow.addressName !== addressName) {
					addressRequestURL = objectToStringForGet(
						'AddressMap',
						addressRequestObj.Address
					)

					if (addressRequestURL) {
						if (addressRequestURL) {
							await addAddressMap(addressRequestURL)
						}
					}
				}
			}
		} else {
			// Add new Address
			addressObj = {
				AddressTypeID: selectedAddressTypeID,
				HouseNumber: houseNumber?.trim(),
				AddressLine1: addressLine1.trim(),
				AddressLine2: addressLine2?.trim(),
				City: city,
				StateID: selectedStateID,
				PostalCode: postalCode?.trim(),
			}

			addressRequestObj = {
				Address: addressObj,
			}

			dataResponse = (await modifyData({
				FileAndFunctionName: 'ManageAddress.tsx addAddress()',
				QueryURL: `AddV2?Params=Address`,
				QueryObj: addressRequestObj,
				ErrorMessage: 'Could not add address',
				ShowErrorMessage: true,
				ShowSuccessMessage: false,
				SuccessMessage: 'Address added successfully',
				LogErrorToDB: true,
				UserName: loggedInUser.email,
			})) as Boolean

			if (dataResponse) {
				addressRequestURL = objectToStringForGet(
					'Address',
					addressRequestObj.Address
				)
				if (addressRequestURL) {
					if (addressRequestURL) {
						timeout(3000)
						await addAddressMap(addressRequestURL)
					}
				}
			}
		}

		setIsLoading(false)
	}

	// Reset Form
	const clearForm = (_addressTypeList: AddressType[]) => {
		setAddressName('')
		setHouseNumber('')
		setAddressLine1('')
		setAddressLine2('')
		setCity('')
		setPostalCode('')
		// Initialize dropdowns to 1st items in respective lists
		setSelectedAddressTypeID(Number(_addressTypeList[0].AddressTypeID))
		setSelectedCountryID(Number(countryList[0].CountryID))
		setSelectedStateID(Number(stateList[0].StateID))
		// Reset error messages
		setAddressNameErrMsg('')
		setHouseNumberErrMsg('')
		setAddressLine1ErrMsg('')
		setAddressLine2ErrMsg('')
		setCityErrMsg('')
		setPostalCodeErrMsg('')
	}

	const handleStateFilter = (_countryID: number): State[] => {
		var filterStateList = stateList
			.filter((x: State) => Number(x.CountryID) === _countryID)
			.sort((a, b) =>
				a.StateName!.localeCompare(b.StateName!, undefined, {
					sensitivity: 'base',
				})
			)

		return filterStateList
	}

	// Toggle add, update address button
	const toggleAddUpdateAddressBtn = () => {
		if (
			addressName === null ||
			isEmptyOrWhitespace(addressName) ||
			addressLine1 === null ||
			isEmptyOrWhitespace(addressLine1) ||
			selectedAddressTypeID === 0 ||
			selectedStateID === 0 ||
			selectedCountryID === 0 ||
			addressNameErrMsg?.length > 0 ||
			houseNumberErrMsg?.length > 0 ||
			addressLine1ErrMsg?.length > 0 ||
			addressLine2ErrMsg?.length > 0 ||
			cityErrMsg?.length > 0 ||
			postalCodeErrMsg?.length > 0
		) {
			return true
		} else {
			if (editAddress) {
				const selectedAddressType = AddressTypes[selectedAddressTypeID]
				const selectedCountry = countryList.find(
					(country: Country) => country.CountryID === selectedCountryID
				)?.CountryName
				const selectedState = stateList.find(
					(state: State) => state.StateID === selectedStateID
				)?.StateName
				//check if something has changed
				if (
					addressName !== selectedRow.AddressName ||
					selectedAddressType !==
						(selectedRow.AddressType === 'Emergency'
							? 'EmergencyAddress'
							: selectedRow.AddressType) ||
					houseNumber !== selectedRow.HouseNumber ||
					addressLine1 !== selectedRow.AddressLine1 ||
					addressLine2 !== selectedRow.AddressLine2 ||
					city !== selectedRow.City ||
					selectedCountry !== selectedRow.Country ||
					selectedState !== selectedRow.State ||
					postalCode !== selectedRow.PostalCode
				) {
					return false
				} else {
					return true
				}
			}
			return false
		}
	}

	return (
		<>
			<Box
				className='address-form-container'
				border={2}
				borderColor='border.primary'
				bgcolor='card.default'>
				<Box className='address-form-content'>
					{/* Content Left */}
					<Box className='address-form-content-left'>
						{/* Address Name */}
						<Box className='address-form-input'>
							<Typography component='p'>
								Address Name: <span style={{ color: 'red' }}>*</span>
							</Typography>
							<StyledTextBox
								fullWidth
								className='input-field'
								variant='outlined'
								required
								onChange={handleAddressName}
								error={addressNameErrMsg.length > 0}
								helperText={addressNameErrMsg}
								value={addressName}
								type='text'
							/>
						</Box>
						{/* House Number */}
						<Box className='address-form-input'>
							<Typography component='p'>House Number:</Typography>
							<StyledTextBox
								fullWidth
								className='input-field'
								variant='outlined'
								onChange={handleHouseNumber}
								error={houseNumberErrMsg.length > 0}
								helperText={houseNumberErrMsg}
								value={houseNumber}
								type='text'
							/>
						</Box>
						{/* Address Line 1 */}
						<Box className='address-form-input'>
							<Typography component='p'>
								Address Line 1: <span style={{ color: 'red' }}>*</span>
							</Typography>
							<StyledTextBox
								fullWidth
								className='input-field'
								variant='outlined'
								required
								onChange={handleAddressLine1}
								error={addressLine1ErrMsg.length > 0}
								helperText={addressLine1ErrMsg}
								value={addressLine1}
								type='text'
							/>
						</Box>
						{/* Address Line 2 */}
						<Box className='address-form-input'>
							<Typography component='p'>Address Line 2:</Typography>
							<StyledTextBox
								fullWidth
								className='input-field'
								variant='outlined'
								onChange={handleAddressLine2}
								error={addressLine2ErrMsg.length > 0}
								helperText={addressLine2ErrMsg}
								value={addressLine2}
								type='text'
							/>
						</Box>
					</Box>
					{/* Content Right */}
					<Box className='address-form-content-left'>
						{/* Address Type */}
						<Box className='address-form-input'>
							<Typography component='p'>
								Address Type: <span style={{ color: 'red' }}>*</span>
							</Typography>
							<StyledSelect
								fullWidth
								required
								variant='outlined'
								className='input-field'
								value={selectedAddressTypeID}
								onChange={handleAddressType}>
								{addressTypeList.map((item: AddressType, index: any) => {
									return (
										<MenuItem key={index} value={item.AddressTypeID}>
											{item.AddressTypeName}
										</MenuItem>
									)
								})}
							</StyledSelect>
						</Box>
						{/* City */}
						<Box className='address-form-input'>
							<Typography component='p'>City:</Typography>
							<StyledTextBox
								fullWidth
								className='input-field'
								variant='outlined'
								onChange={handleCity}
								value={city}
								error={cityErrMsg.length > 0}
								helperText={cityErrMsg}
								type='text'
							/>
						</Box>
						{/* Country */}
						<Box className='address-form-input'>
							<Typography component='p'>
								Country: <span style={{ color: 'red' }}>*</span>
							</Typography>
							<StyledSelect
								fullWidth
								required
								variant='outlined'
								className='input-field'
								value={selectedCountryID}
								onChange={handleCountry}>
								{countryListDisplay.map((item: Country, index: any) => {
									return (
										<MenuItem key={index} value={item.CountryID}>
											{item.CountryName}
										</MenuItem>
									)
								})}
							</StyledSelect>
						</Box>
						{/* State */}
						<Box className='address-form-input'>
							<Typography component='p'>
								State: <span style={{ color: 'red' }}>*</span>
							</Typography>
							<StyledSelect
								fullWidth
								required
								variant='outlined'
								className='input-field'
								value={selectedStateID}
								onChange={handleState}>
								{stateListDisplay.map((item: State, index: any) => {
									return (
										<MenuItem key={index} value={item.StateID}>
											{item.StateName}
										</MenuItem>
									)
								})}
							</StyledSelect>
						</Box>
						{/* Postal Code */}
						<Box className='address-form-input'>
							<Typography component='p'>Postal Code:</Typography>
							<StyledTextBox
								fullWidth
								className='input-field'
								variant='outlined'
								onChange={handlePostalCode}
								value={postalCode}
								error={postalCodeErrMsg.length > 0}
								helperText={postalCodeErrMsg}
								type='text'
							/>
						</Box>
					</Box>
				</Box>
				{/* Add:Update */}
				<Box sx={{ marginBottom: 3 }} className='address-form-footer'>
					{/* Clear filters */}
					<LoadingButton
						disabled={toggleAddUpdateAddressBtn()}
						loading={isLoading}
						startIcon={<SaveAsOutlinedIcon />}
						variant='outlined'
						onClick={addAddress}>
						{editAddress ? 'Update' : 'Add'} Address
					</LoadingButton>
				</Box>
			</Box>
		</>
	)
}

export default ManageAddress
