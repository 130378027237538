/* eslint-disable no-loop-func */
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import PostAndRetrieveDataHook from '../../../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import {
	CustomerTrunkGroupFQDNMap,
	CustomerTrunkGroupIP,
	CustomerTrunkGroupRegionMap,
	DDI,
	DDIRange,
	DDIRangeTrunkMap,
	FQDN,
	InternalTrunkGroupIP,
	Order,
	TrunkGroup,
} from '../../../../utils/interfaces/DBModels'
import {
	RegionTrunkDisplay,
	RegionTrunkIDList,
	TrunkIPListDisplay,
} from '../../../../utils/interfaces/ComponentModels'
import {
	CallDeliveryMethods,
	RegionOptions,
	Services,
	TrunkTypes,
} from '../../../../utils/enums/enums'
import LoadingCard from '../../../shared/loading/loadingCard/LoadingCard'
import PublicIcon from '@mui/icons-material/Public'
import DeleteIcon from '@mui/icons-material/Delete'
import './TrunkOverviewDisplay.scss'
import { StyledChip } from '../../../shared/styledComponents/StyledChip/StyledChip'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import {
	BetaObject,
	PostHookObj,
	BYOCSBCRequestModel,
	SBCTrunkIPModel,
} from '../../../../utils/interfaces/APIModels'
import PostUpdateDataHook from '../../../../utils/customHooks/APICalls/PostUpdateDataHook'
import {
	showErrorToast,
	showSuccessToast,
	toBetaString,
} from '../../../../utils/helperFunctions/helperFunctions'
import ErrorLogging from '../../../../utils/customHooks/ErrorLogging'
import { useQueueSBCRequestMutation } from '../../../../services/proxyAPIData'

const TrunkOverviewDisplay = ({ customerID }: { customerID: string }) => {
	// Global
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	// Hooks
	const {
		postAndRetrieveDataFromDB,
		postAndRetrieveDataError,
		postAndRetrieveDataLoading,
	} = PostAndRetrieveDataHook()
	const {
		postUpdateDataWithoutRefetch,
		apiPostWithoutRefetchError,
		postWithoutRefetchLoading,
	} = PostUpdateDataHook()

	// API Call
	const [
		postQueueSBCCleanUpRequest,
		{ isLoading: postRemoveSBCConfigurationLoading },
	] = useQueueSBCRequestMutation()

	// Error handling
	const { addErrorLog } = ErrorLogging()

	// Display constant
	const [trunkGroupID, setTrunkGroupID] = useState('')
	const [regionTrunkDisplay, setRegionTrunkDisplay] = useState(
		[] as RegionTrunkDisplay[]
	)

	// Flags
	const [trunksCallMade, setTrunksCallMade] = useState(false)
	const [apiCallsDone, setAPICallsDone] = useState(false)
	const [amerExists, setAMERExists] = useState(false)
	const [apacExists, setAPACExists] = useState(false)
	const [emeaExists, setEMEAExists] = useState(false)
	const [deleteAMERLoading, setDeleteAMERLoading] = useState(false)
	const [deleteAPACLoading, setDeleteAPACLoading] = useState(false)
	const [deleteEMEALoading, setDeleteEMEALoading] = useState(false)
	const [trunkGroup, setTrunkGroup] = useState({} as TrunkGroup)

	// General
	const [currentRegionToDelete, setCurrentRegionToDelete] = useState(
		{} as RegionTrunkDisplay
	)
	const [openDialog, setOpenDialog] = useState(false)
	const [customerTrunkGroupIPs, setCustomerTrunkGroupIPs] = useState(
		[] as CustomerTrunkGroupIP[]
	)

	useEffect(() => {
		if (!postAndRetrieveDataLoading) {
			if (!trunksCallMade) {
				setTrunksCallMade(true)
				getTrunks()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		postAndRetrieveDataError,
		postAndRetrieveDataLoading,
		apiPostWithoutRefetchError,
		postWithoutRefetchLoading,
		postRemoveSBCConfigurationLoading,
		// Flags
		trunksCallMade,
		apiCallsDone,
	])

	// *************************************** API Calls *************************************** //

	// GET: Return the trunk IPs
	const getTrunks = async () => {
		// Make hook call to return customer trunks
		var dataResponse = await postAndRetrieveDataFromDB(
			'Customer Trunk IP',
			`TrunkGroup.CustomerTrunkGroupIP.CustomerTrunkGroupRegionMap.Where(TrunkGroup.CustomerID = '${customerID}' & TrunkGroup.TrunkTypeID = '${TrunkTypes.BYOC}')`,
			false,
			true
		)
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var trunkResponse = dataResponse.Obj.TrunkGroupList as TrunkGroup[]

			if (trunkResponse && trunkResponse.length > 0) {
				// Variables to build
				var trunkGroupID = ''
				var customerIPList = [] as CustomerTrunkGroupIP[]
				var regionTrunkList = [] as RegionTrunkIDList[]
				// One trunk group - BYOC
				for (let tg = 0; tg < trunkResponse.length; tg++) {
					setTrunkGroup(trunkResponse[tg])
					// Trunk Group ID
					trunkGroupID = trunkResponse[tg].TrunkGroupID + ''
					// Customer IP List
					var customerTrunkGroupIPList = trunkResponse[tg]
						.CustomerTrunkGroupIPList as CustomerTrunkGroupIP[]

					if (customerTrunkGroupIPList && customerTrunkGroupIPList.length > 0) {
						setCustomerTrunkGroupIPs(customerTrunkGroupIPList)
						// Push to list
						customerIPList.push(...customerTrunkGroupIPList)
						// Loop through the list
						for (let cip = 0; cip < customerTrunkGroupIPList.length; cip++) {
							// Regions
							var customerTrunkRegionMapList = customerTrunkGroupIPList[cip]
								.CustomerTrunkGroupRegionMapList as CustomerTrunkGroupRegionMap[]

							if (
								customerTrunkRegionMapList &&
								customerTrunkRegionMapList.length > 0
							) {
								// Loop through to build region trunk list
								for (
									let reg = 0;
									reg < customerTrunkRegionMapList.length;
									reg++
								) {
									// Add to region trunk list
									regionTrunkList.push({
										CarrierDescription:
											customerTrunkGroupIPList[cip].CustomerTrunkIPName,
										CustomerTrunkGroupIPID:
											customerTrunkRegionMapList[reg].CustomerTrunkGroupIPID,
										RegionID: customerTrunkRegionMapList[reg].RegionID,
									})
								}
							}
						}
					}
				}

				// After the region trunk list is built, loop through for display
				if (regionTrunkList && regionTrunkList.length > 0) {
					// Variables to build
					var amerExists = false
					var apacExists = false
					var emeaExists = false
					var amerCarrierDescription = ''
					var apacCarrierDescription = ''
					var emeaCarrierDescription = ''
					var amerTrunkIPList = [] as TrunkIPListDisplay[]
					var apacTrunkIPList = [] as TrunkIPListDisplay[]
					var emeaTrunkIPList = [] as TrunkIPListDisplay[]
					var customerIPItem = {} as CustomerTrunkGroupIP | undefined
					// Loop through list to build IP displays
					for (let rt = 0; rt < regionTrunkList.length; rt++) {
						// Check the region
						switch (regionTrunkList[rt].RegionID) {
							// AMER
							case RegionOptions.AMER:
								amerExists = true
								amerCarrierDescription =
									regionTrunkList[rt].CarrierDescription + ''
								// Find the IP
								customerIPItem = customerIPList.find(
									(cip) =>
										cip.CustomerTrunkGroupIPID + '' ===
										regionTrunkList[rt].CustomerTrunkGroupIPID + ''
								)
								if (customerIPItem) {
									// Push to AMER List
									amerTrunkIPList.push({
										CustomerTrunkGroupIPID:
											customerIPItem.CustomerTrunkGroupIPID,
										CustomerTrunkIP: customerIPItem.CustomerTrunkIP,
									})
								}
								break
							// APAC
							case RegionOptions.APAC:
								apacExists = true
								apacCarrierDescription =
									regionTrunkList[rt].CarrierDescription + ''
								// Find the IP
								customerIPItem = customerIPList.find(
									(cip) =>
										cip.CustomerTrunkGroupIPID + '' ===
										regionTrunkList[rt].CustomerTrunkGroupIPID + ''
								)
								if (customerIPItem) {
									// Push to AMER List
									apacTrunkIPList.push({
										CustomerTrunkGroupIPID:
											customerIPItem.CustomerTrunkGroupIPID,
										CustomerTrunkIP: customerIPItem.CustomerTrunkIP,
									})
								}
								break
							// EMEA
							case RegionOptions.EMEA:
								emeaExists = true
								emeaCarrierDescription =
									regionTrunkList[rt].CarrierDescription + ''
								// Find the IP
								customerIPItem = customerIPList.find(
									(cip) =>
										cip.CustomerTrunkGroupIPID + '' ===
										regionTrunkList[rt].CustomerTrunkGroupIPID + ''
								)
								if (customerIPItem) {
									// Push to AMER List
									emeaTrunkIPList.push({
										CustomerTrunkGroupIPID:
											customerIPItem.CustomerTrunkGroupIPID,
										CustomerTrunkIP: customerIPItem.CustomerTrunkIP,
									})
								}
								break
						}
					}
					// Display
					var regionTrunkDisplayList = [] as RegionTrunkDisplay[]
					// Push to display list
					// AMER
					if (amerExists && amerTrunkIPList.length > 0) {
						regionTrunkDisplayList.push({
							CarrierDescription: amerCarrierDescription,
							RegionID: RegionOptions.AMER,
							TrunkIPList: amerTrunkIPList,
						})
					}
					// APAC
					if (apacExists && apacTrunkIPList.length > 0) {
						regionTrunkDisplayList.push({
							CarrierDescription: apacCarrierDescription,
							RegionID: RegionOptions.APAC,
							TrunkIPList: apacTrunkIPList,
						})
					}
					// EMEA
					if (emeaExists && emeaTrunkIPList.length > 0) {
						regionTrunkDisplayList.push({
							CarrierDescription: emeaCarrierDescription,
							RegionID: RegionOptions.EMEA,
							TrunkIPList: emeaTrunkIPList,
						})
					}

					// Set variables
					setTrunkGroupID(trunkGroupID)
					setRegionTrunkDisplay(regionTrunkDisplayList)
					setAMERExists(amerExists)
					setAPACExists(apacExists)
					setEMEAExists(emeaExists)
				}
			}
		} else {
			// Clear everything
			clearVariables()
		}

		// API Call done
		setAPICallsDone(true)
	}

	// GET: Return DDI Mapping for trunks if exists
	const getDDIRangeTrunkMapping = async (
		regionID: number,
		deleteAll: boolean
	) => {
		// Variables to build
		var ddiRangeTrunkMapList = [] as DDIRangeTrunkMap[]

		// Make API call
		var dataResponse = await postAndRetrieveDataFromDB(
			'DDI Range Trunk Map',
			`DDIRangeTrunkMap.Where(DDIRangeTrunkMap.CustomerTrunkGroupIPID ~ '${trunkGroupID}')`,
			false,
			true
		)
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var ddiTrunkMapResponse = dataResponse.Obj
				.DDIRangeTrunkMapList as DDIRangeTrunkMap[]

			if (ddiTrunkMapResponse && ddiTrunkMapResponse.length > 0) {
				// Check to delete all
				if (deleteAll) {
					ddiRangeTrunkMapList = ddiTrunkMapResponse
				} else {
					// Check region
					for (let rt = 0; rt < regionTrunkDisplay.length; rt++) {
						if (Number(regionTrunkDisplay[rt].RegionID) === regionID) {
							// Loop through trunk ip list
							var trunkIPList = regionTrunkDisplay[rt]
								.TrunkIPList as TrunkIPListDisplay[]

							if (trunkIPList && trunkIPList.length > 0) {
								for (let tip = 0; tip < trunkIPList.length; tip++) {
									var ddiTrunkObj = ddiTrunkMapResponse.find(
										(ddi) =>
											ddi.CustomerTrunkGroupIPID + '' ===
											trunkIPList[tip].CustomerTrunkGroupIPID + ''
									)

									if (ddiTrunkObj) {
										ddiRangeTrunkMapList.push(ddiTrunkObj)
									}
								}
							}
						}
					}
				}
			}
		}

		return ddiRangeTrunkMapList
	}

	// GET: Return FQDN Mapping
	const getFQDNMapping = async (regionID: number, deleteAll: boolean) => {
		// Make call
		// Variables to build
		var customerTrunkGroupFQDNMapList = [] as CustomerTrunkGroupFQDNMap[]

		// Make API call
		var dataResponse = await postAndRetrieveDataFromDB(
			'Customer Trunk FQDN Mappings',
			`CustomerTrunkGroupFQDNMap.Where(CustomerTrunkGroupFQDNMap.CustomerTrunkGroupIPID ~ '${trunkGroupID}')`,
			false,
			true
		)
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var customerTrunkGroupFQDNMapResponse = dataResponse.Obj
				.CustomerTrunkGroupFQDNMapList as CustomerTrunkGroupFQDNMap[]

			if (
				customerTrunkGroupFQDNMapResponse &&
				customerTrunkGroupFQDNMapResponse.length > 0
			) {
				if (deleteAll) {
					customerTrunkGroupFQDNMapList = customerTrunkGroupFQDNMapResponse
				} else {
					var regionToDelete = regionTrunkDisplay.find(
						(reg) => Number(reg.RegionID) === regionID
					)

					if (regionToDelete) {
						var regionIPList =
							regionToDelete.TrunkIPList as TrunkIPListDisplay[]

						if (regionIPList && regionIPList.length > 0) {
							// Loop through to build delete list
							for (let rip = 0; rip < regionIPList.length; rip++) {
								var fqdnMapList = customerTrunkGroupFQDNMapResponse.filter(
									(ctg) =>
										ctg.CustomerTrunkGroupIPID + '' ===
										regionIPList[rip].CustomerTrunkGroupIPID + ''
								)

								if (fqdnMapList && fqdnMapList.length > 0) {
									customerTrunkGroupFQDNMapList.push(...fqdnMapList)
								}
							}
						}
					}
				}
			}
		}

		return customerTrunkGroupFQDNMapList
	}

	// GET: Return teams order ID
	const getTeamsOrderID = async () => {
		// Variable to return
		var orderID = 0
		// Make API call
		var dataResponse = await postAndRetrieveDataFromDB(
			'Teams Order',
			`Order.OrderSKU.First(Order.CustomerID = '${customerID}' & OrderSKU.SkuTypeID ^ 'VC-DR-SIP-SER-BAS,VC-TEAMS-MANG-DEP-UC')`,
			false,
			true
		)
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var orderResponse = dataResponse.Obj.Order as Order

			if (orderResponse && orderResponse.OrderID) {
				orderID = orderResponse.OrderID
			}
		}

		return orderID
	}

	// POST: Delete trunks
	const deleteTrunks = async () => {
		// Check what is being deleted
		if (currentRegionToDelete && currentRegionToDelete.RegionID) {
			// Set loading
			handleDeleteDisplay(currentRegionToDelete.RegionID)

			// Variables
			var deleteAll = false
			// If only one region exists, delete the trunk group as well
			switch (currentRegionToDelete.RegionID) {
				// AMER
				case RegionOptions.AMER:
					if (amerExists && !apacExists && !emeaExists) {
						deleteAll = true
					}
					break
				// APAC
				case RegionOptions.APAC:
					if (apacExists && !amerExists && !emeaExists) {
						deleteAll = true
					}
					break
				// EMEA
				case RegionOptions.EMEA:
					if (emeaExists && !amerExists && !apacExists) {
						deleteAll = true
					}
					break
			}

			// Check for DDI Mapping - If exists, delete DDI Mapping, DDIs and DDI Range as well accordingly
			var ddiRangeTrunkMapList = await getDDIRangeTrunkMapping(
				currentRegionToDelete.RegionID,
				deleteAll
			)

			var containsDDIRangeMappings = false
			var ddiList = [] as DDI[]
			var ddiRangeList = [] as DDIRange[]
			if (ddiRangeTrunkMapList && ddiRangeTrunkMapList.length > 0) {
				containsDDIRangeMappings = true

				// Build lists
				for (let ddi = 0; ddi < ddiRangeTrunkMapList.length; ddi++) {
					// Push
					ddiList.push({
						DDIRangeID: ddiRangeTrunkMapList[ddi].DDIRangeID,
					})
					ddiRangeList.push({
						DDIRangeID: ddiRangeTrunkMapList[ddi].DDIRangeID,
					})
				}
			}

			// Build variables to delete - Customer Trunk IPs, Internal IPs, Region Map, FQDN and FQDN mapping
			var customerTrunkGroupIPList = [] as CustomerTrunkGroupIP[]
			var internalTrunkGroupIPList = [] as InternalTrunkGroupIP[]
			var customerTrunkGroupRegionMapList = [] as CustomerTrunkGroupRegionMap[]
			// Delete All
			if (deleteAll) {
				for (let rt = 0; rt < regionTrunkDisplay.length; rt++) {
					var trunkIPList = regionTrunkDisplay[rt]
						.TrunkIPList as TrunkIPListDisplay[]

					if (trunkIPList && trunkIPList.length > 0) {
						for (let tip = 0; tip < trunkIPList.length; tip++) {
							// Push to lists
							customerTrunkGroupIPList.push({
								CustomerTrunkGroupIPID: trunkIPList[tip].CustomerTrunkGroupIPID,
							})
							internalTrunkGroupIPList.push({
								CustomerTrunkGroupIPID: trunkIPList[tip].CustomerTrunkGroupIPID,
							})
							customerTrunkGroupRegionMapList.push({
								CustomerTrunkGroupIPID: trunkIPList[tip].CustomerTrunkGroupIPID,
							})
						}
					}
				}
			} else {
				// Delete by region
				var regionToDelete = regionTrunkDisplay.find(
					(rt) => Number(rt.RegionID) === Number(currentRegionToDelete.RegionID)
				)

				if (regionToDelete) {
					var regionTrunkIPList =
						regionToDelete.TrunkIPList as TrunkIPListDisplay[]

					if (regionTrunkIPList && regionTrunkIPList.length > 0) {
						for (let rti = 0; rti < regionTrunkIPList.length; rti++) {
							// Push to lists
							customerTrunkGroupIPList.push({
								CustomerTrunkGroupIPID:
									regionTrunkIPList[rti].CustomerTrunkGroupIPID,
							})
							internalTrunkGroupIPList.push({
								CustomerTrunkGroupIPID:
									regionTrunkIPList[rti].CustomerTrunkGroupIPID,
							})
							customerTrunkGroupRegionMapList.push({
								CustomerTrunkGroupIPID:
									regionTrunkIPList[rti].CustomerTrunkGroupIPID,
							})
						}
					}
				}
			}

			// Get the FQDN Mapping and FQDNs
			var customerTrunkGroupFQDNMapList = await getFQDNMapping(
				currentRegionToDelete.RegionID,
				deleteAll
			)

			var fqdnList = [] as FQDN[]
			if (
				customerTrunkGroupFQDNMapList &&
				customerTrunkGroupFQDNMapList.length > 0
			) {
				// Build FQDN List
				for (let ctg = 0; ctg < customerTrunkGroupFQDNMapList.length; ctg++) {
					fqdnList.push({
						FQDNID: customerTrunkGroupFQDNMapList[ctg].FQDNID,
					})
				}
			}

			// Build the object to delete
			var deleteTrunkObj = {}
			var requestURL = ''
			if (deleteAll) {
				// Trunk Group Delete Object
				var trunkGroupObj: TrunkGroup = {
					TrunkGroupID: trunkGroupID,
				}

				if (containsDDIRangeMappings) {
					requestURL =
						'DeleteV2?Params=DDIRangeTrunkMap:list,DDI:list,DDIRange:list,InternalTrunkGroupIP:list,CustomerTrunkGroupRegionMap:list,CustomerTrunkGroupFQDNMap:list,FQDN:list,CustomerTrunkGroupIP:list,TrunkGroup'
					deleteTrunkObj = {
						DDIRangeTrunkMapList: ddiRangeTrunkMapList,
						DDIList: ddiList,
						DDIRangeList: ddiRangeList,
						InternalTrunkGroupIPList: internalTrunkGroupIPList,
						CustomerTrunkGroupRegionMapList: customerTrunkGroupRegionMapList,
						CustomerTrunkGroupFQDNMapList: customerTrunkGroupFQDNMapList,
						FQDNList: fqdnList,
						CustomerTrunkGroupIPList: customerTrunkGroupIPList,
						TrunkGroup: trunkGroupObj,
					}
				} else {
					requestURL =
						'DeleteV2?Params=InternalTrunkGroupIP:list,CustomerTrunkGroupRegionMap:list,CustomerTrunkGroupFQDNMap:list,FQDN:list,CustomerTrunkGroupIP:list,TrunkGroup'
					deleteTrunkObj = {
						InternalTrunkGroupIPList: internalTrunkGroupIPList,
						CustomerTrunkGroupRegionMapList: customerTrunkGroupRegionMapList,
						CustomerTrunkGroupFQDNMapList: customerTrunkGroupFQDNMapList,
						FQDNList: fqdnList,
						CustomerTrunkGroupIPList: customerTrunkGroupIPList,
						TrunkGroup: trunkGroupObj,
					}
				}
			} else {
				if (containsDDIRangeMappings) {
					requestURL =
						'DeleteV2?Params=DDIRangeTrunkMap:list,DDI:list,DDIRange:list,InternalTrunkGroupIP:list,CustomerTrunkGroupRegionMap:list,CustomerTrunkGroupFQDNMap:list,FQDN:list,CustomerTrunkGroupIP:list'
					deleteTrunkObj = {
						DDIRangeTrunkMapList: ddiRangeTrunkMapList,
						DDIList: ddiList,
						DDIRangeList: ddiRangeList,
						InternalTrunkGroupIPList: internalTrunkGroupIPList,
						CustomerTrunkGroupRegionMapList: customerTrunkGroupRegionMapList,
						CustomerTrunkGroupFQDNMapList: customerTrunkGroupFQDNMapList,
						FQDNList: fqdnList,
						CustomerTrunkGroupIPList: customerTrunkGroupIPList,
					}
				} else {
					requestURL =
						'DeleteV2?Params=InternalTrunkGroupIP:list,CustomerTrunkGroupRegionMap:list,CustomerTrunkGroupFQDNMap:list,FQDN:list,CustomerTrunkGroupIP:list'
					deleteTrunkObj = {
						InternalTrunkGroupIPList: internalTrunkGroupIPList,
						CustomerTrunkGroupRegionMapList: customerTrunkGroupRegionMapList,
						CustomerTrunkGroupFQDNMapList: customerTrunkGroupFQDNMapList,
						FQDNList: fqdnList,
						CustomerTrunkGroupIPList: customerTrunkGroupIPList,
					}
				}
			}

			// Post call to delete
			var postHookObj: PostHookObj = {
				Action: 'Delete Trunks',
				LoggedInUser: loggedInUser,
				RequestURL: requestURL,
				RequestObj: deleteTrunkObj,
				ShowSuccessMessage: false,
				ShowErrorMessage: true,
				ErrorMessage: 'An error occurred when deleting trunks',
				LogErrorToDB: true,
			}

			var deleteSuccess = true
			await postUpdateDataWithoutRefetch(postHookObj).catch(async (error) => {
				if (error) {
					deleteSuccess = false
				}
			})

			if (deleteSuccess) {
				// Call SBCs to clean up
				var sbcRemoveSuccess = await removeSBCConfiguration(
					currentRegionToDelete.RegionID
				)

				if (!sbcRemoveSuccess) {
					showErrorToast(
						'The trunks have been removed successfully but the removal of the configuration failed to initialize'
					)
				} else {
					showSuccessToast('The trunks have been removed successfully')
				}

				getTrunks()
				resetFlags()
			}
		}
	}

	// POST: Remove SBC Configuration
	const removeSBCConfiguration = async (regionID: number) => {
		// Return
		var sbcRemoveSuccess = true

		// Variables
		var errorMessage = ''

		// Get the first teams order
		var orderID = await getTeamsOrderID()

		if (orderID && orderID > 0) {
			// AMER
			var sbcTrunkIPModels: SBCTrunkIPModel[] = []
			customerTrunkGroupIPs.forEach((trunkGroupIP) => {
				if (
					trunkGroupIP.CustomerTrunkGroupRegionMapList?.find(
						(x) => Number(x.RegionID) === regionID
					)
				) {
					sbcTrunkIPModels.push({
						CustomerTrunkIP: trunkGroupIP.CustomerTrunkIP + '',
						InternetProtocolID: Number(trunkGroupIP.InternetProtocolID),
						Port: trunkGroupIP.Port + '',
					})
				}
			})

			if (regionID === RegionOptions.AMER) {
				var amerRemoveSBCRequest: BYOCSBCRequestModel = {
					CustomerID: customerID,
					ServiceOrTrunkID: trunkGroupID,
					SBCTrunkIPs: sbcTrunkIPModels,
					Region: 'AMER',
					Environment: process.env.REACT_APP_SBC_ENVIRONMENT + '',
					CallDeliveryMethodID: CallDeliveryMethods.Hunt,
					IsConfigCreation: false,
					ServiceTypeID: Services.BYOC,
					TrunkQuantity: trunkGroup.Quantity,
				}
				var betaObj: BetaObject = {
					Content: await toBetaString(JSON.stringify(amerRemoveSBCRequest)),
				}

				await postQueueSBCCleanUpRequest(betaObj)
					.unwrap()
					.then(() => {})
					.catch(async (error) => {
						if (error) {
							sbcRemoveSuccess = false
							errorMessage =
								'An error occurred when initialising SBC config removal. Request body: ' +
								JSON.stringify(amerRemoveSBCRequest) +
								`Error ` +
								error?.data
						}
					})
					.finally(() => {
						// Log error to db
						if (sbcRemoveSuccess === false) {
							addErrorLog('Remove SBC Config', errorMessage)
						}
					})
			}
			// APAC
			else if (regionID === RegionOptions.APAC) {
				var apacRemoveSBCRequest: BYOCSBCRequestModel = {
					CustomerID: customerID,
					SBCTrunkIPs: sbcTrunkIPModels,
					Region: 'APAC',
					ServiceOrTrunkID: trunkGroupID,
					Environment: process.env.REACT_APP_SBC_ENVIRONMENT + '',
					CallDeliveryMethodID: CallDeliveryMethods.Hunt,
					IsConfigCreation: false,
					ServiceTypeID: Services.BYOC,
					TrunkQuantity: trunkGroup.Quantity,
				}
				var betaObj: BetaObject = {
					Content: await toBetaString(JSON.stringify(apacRemoveSBCRequest)),
				}

				await postQueueSBCCleanUpRequest(betaObj)
					.unwrap()
					.then(() => {})
					.catch(async (error) => {
						if (error) {
							sbcRemoveSuccess = false
							errorMessage =
								'An error occurred when initialising SBC config removal. Request body: ' +
								JSON.stringify(apacRemoveSBCRequest) +
								`Error ` +
								error?.data
						}
					})
					.finally(() => {
						// Log error to db
						if (sbcRemoveSuccess === false) {
							addErrorLog('Remove SBC Config', errorMessage)
						}
					})
			}
			// EMEA
			else if (regionID === RegionOptions.EMEA) {
				var emeaRemoveSBCRequest: BYOCSBCRequestModel = {
					CustomerID: customerID,
					SBCTrunkIPs: sbcTrunkIPModels,
					Region: 'EMEA',
					ServiceOrTrunkID: trunkGroupID,
					Environment: process.env.REACT_APP_SBC_ENVIRONMENT + '',
					CallDeliveryMethodID: CallDeliveryMethods.Hunt,
					IsConfigCreation: false,
					ServiceTypeID: Services.BYOC,
					TrunkQuantity: trunkGroup.Quantity,
				}
				var betaObj: BetaObject = {
					Content: await toBetaString(JSON.stringify(emeaRemoveSBCRequest)),
				}

				await postQueueSBCCleanUpRequest(betaObj)
					.unwrap()
					.then(() => {})
					.catch(async (error) => {
						if (error) {
							sbcRemoveSuccess = false
							errorMessage =
								'An error occurred when initialising SBC config removal. Request body: ' +
								JSON.stringify(emeaRemoveSBCRequest) +
								`Error ` +
								error?.data
						}
					})
					.finally(() => {
						// Log error to db
						if (sbcRemoveSuccess === false) {
							addErrorLog('Remove SBC Config', errorMessage)
						}
					})
			}
		}

		return sbcRemoveSuccess
	}

	// *************************************** END API Calls *************************************** //

	// Handle functions
	const handleOpenDialog = (regionID: number) => {
		// Popup message with disclaimer
		if (regionID) {
			// Get current card
			var currentCard = regionTrunkDisplay.find(
				(rt) => Number(rt.RegionID) === regionID
			)
			if (currentCard) {
				setCurrentRegionToDelete(currentCard)
				setOpenDialog(true)
			}
		}
	}

	const handleCloseDialog = () => {
		setOpenDialog(false)
		setCurrentRegionToDelete({} as RegionTrunkDisplay)
	}

	// Handle the loading for delete
	const handleDeleteDisplay = (regionID: number) => {
		// Check what is being deleted
		switch (regionID) {
			// AMER
			case RegionOptions.AMER:
				setDeleteAMERLoading(true)
				break
			// APAC
			case RegionOptions.APAC:
				setDeleteAPACLoading(true)
				break
			// EMEA
			case RegionOptions.EMEA:
				setDeleteEMEALoading(true)
				break
		}

		// Close dialog
		setOpenDialog(false)
	}

	// Clear flags
	const resetFlags = () => {
		// Flags
		setDeleteAMERLoading(false)
		setDeleteAPACLoading(false)
		setDeleteEMEALoading(false)
	}

	// Clear everything if no trunks found
	const clearVariables = () => {
		// Flags
		setDeleteAMERLoading(false)
		setDeleteAPACLoading(false)
		setDeleteEMEALoading(false)
		// Trunk
		setTrunkGroupID('')
		setRegionTrunkDisplay([])
		setCurrentRegionToDelete({})
		setCustomerTrunkGroupIPs([])
	}

	return apiCallsDone ? (
		<>
			<Box className='trunks-container'>
				{/* Trunk Display */}
				{regionTrunkDisplay.length > 0 ? (
					<Box className='trunks-content'>
						{/* Region Card Container */}
						<Box className='trunk-region-container'>
							{regionTrunkDisplay.map((item, index) => (
								<Box className='trunk-region' key={index}>
									{/* Header */}
									<Box className='trunk-region-header-container'>
										<Box className='trunk-region-header'>
											{/* Icon */}
											<PublicIcon sx={{ color: 'text.primary' }} />
											{/* Text */}
											<Typography component='h2'>
												{RegionOptions[Number(item.RegionID)]}
											</Typography>
										</Box>
										{/* Delete button */}
										<Box className='trunk-region-delete'>
											<Tooltip
												title={`Delete ${
													RegionOptions[Number(item.RegionID)]
												} Trunks`}>
												<span>
													<IconButton
														onClick={() =>
															handleOpenDialog(Number(item.RegionID))
														}>
														<DeleteIcon />
													</IconButton>
													{/* Loading */}
													{/* AMER */}
													{item.RegionID === RegionOptions.AMER &&
													deleteAMERLoading ? (
														<CircularProgress
															size={52}
															sx={{
																position: 'absolute',
																top: -6,
																left: -6,
																zIndex: 1,
																color: '#0075C9!important',
															}}
														/>
													) : null}
													{/* APAC */}
													{item.RegionID === RegionOptions.APAC &&
													deleteAPACLoading ? (
														<CircularProgress
															size={52}
															sx={{
																position: 'absolute',
																top: -6,
																left: -6,
																zIndex: 1,
																color: '#0075C9!important',
															}}
														/>
													) : null}
													{/* EMEA */}
													{item.RegionID === RegionOptions.EMEA &&
													deleteEMEALoading ? (
														<CircularProgress
															size={52}
															sx={{
																position: 'absolute',
																top: -6,
																left: -6,
																zIndex: 1,
																color: '#0075C9!important',
															}}
														/>
													) : null}
												</span>
											</Tooltip>
										</Box>
									</Box>
									{/* Carrier Description */}
									<Typography component='h4'>
										<span>Carrier Description:</span> {item.CarrierDescription}
									</Typography>
									{/* IP List */}
									<Box className='trunk-region-ip-container'>
										{item.TrunkIPList?.map((ipItem, ipIndex) => (
											<StyledChip
												key={ipIndex}
												label={ipItem.CustomerTrunkIP}
												variant='outlined'
											/>
										))}
									</Box>
								</Box>
							))}
						</Box>
						{/* Dialog for deleting trunks */}
						<Dialog
							open={openDialog}
							aria-labelledby='alert-dialog-title'
							aria-describedby='alert-dialog-description'>
							<DialogTitle id='alert-dialog-title'>
								Are you sure you wish to delete this trunk?
							</DialogTitle>
							<DialogContent>
								<DialogContentText id='alert-dialog-description'>
									{`Deleting this carrier description '${currentRegionToDelete.CarrierDescription}' will remove all TNs and trunk configuration linked to this carrier description for this customer. Proceed?`}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleCloseDialog} sx={{ color: '#db3d47' }}>
									Cancel
								</Button>
								<Button onClick={deleteTrunks} autoFocus>
									Delete Trunks
								</Button>
							</DialogActions>
						</Dialog>
					</Box>
				) : (
					// Empty display
					<Box className='empty-trunk-container'>
						<Box className='empty-trunk-content'>
							{/* Image */}
							<img
								src={require('../../../../assets/icons/form/trunks/trunks-icon.png')}
								alt='Blue Trunks Icon'
							/>
							{/* Heading */}
							<Typography component='h1'>No trunks have been found</Typography>
							{/* Text */}
							<Typography component='p'>
								This customer does not have any trunks assigned
							</Typography>
						</Box>
					</Box>
				)}
			</Box>
		</>
	) : (
		<LoadingCard
			loadingCardTitle='Loading Trunk Information'
			loadingCardDescription='Please wait as we load the trunk information for the customer'
		/>
	)
}

export default TrunkOverviewDisplay
