import { MsalProvider } from '@azure/msal-react'
import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import { Box, PaletteMode, ThemeProvider } from '@mui/material'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.scss'
import Footer from './components/shared/footer/Footer'
import Header from './components/shared/navigation/Header/Header'
import Sidebar from './components/shared/navigation/Sidebar/Sidebar'
import Dashboard from './pages/dashboard/Dashboard'
import { useAppDispatch } from './store/hooks'
import { setPartner } from './store/reducers/reducers'
import { getThemeByURL } from './styles/themes'
import CustomerManagement from './pages/dashboard/CustomerManagement/CustomerManagement'
import CustomerOverview from './pages/dashboard/CustomerOverview/CustomerOverview'
import Breadcrumbs from './components/shared/navigation/TitleAndBreadcrumbs/TitleAndBreadcrumbs'
import { ProtectedRoute } from './components/shared/protected/ProtectedRoute'
import { Permissions } from './utils/enums/enums'
import POCInstances from './pages/order/POCInstances'
import AddPOCOrder from './pages/order/AddPOCOrder'
import { ToastContainer } from 'react-toastify'
import DDIManagement from './pages/dashboard/DDIManagement/DDIManagement'
import 'react-toastify/dist/ReactToastify.css'
import LoginRedirect from './pages/login/LoginRedirect'
import Support from './pages/support/Support'
import OrderManagement from './pages/dashboard/OrderManagement/OrderManagement'
import AddTrunk from './pages/dashboard/AddTrunk/AddTrunk'
import TeamsUserManagement from './pages/dashboard/TeamsUserManagement/TeamsUserManagement'
import TeamsOverview from './pages/dashboard/TeamsOverview/TeamsOverview'
import AppRegistrationRedirect from './pages/AppRegistrationRedirect/AppRegistrationRedirect'
import PortalUserManagement from './pages/dashboard/PortalUserManagement/PortalUserManagement'
import { clarity } from 'react-microsoft-clarity'
import { useEffect } from 'react'
import TDRSelfServiceProvisioning from './pages/tdrSelfServiceProvisioning/TDRSelfServiceProvisioning'
import LeadManagement from './pages/dashboard/Leads/LeadManagement'
import PriceBookManagement from './pages/dashboard/PriceBookManagement/PriceBookManagement'
import AcceptCompliance from './pages/AcceptCompliance/AcceptCompliance'
import { TUMProvider } from './utils/context/TUMContext'
import AddressManagement from './pages/AddressManagement/AddressManagement'

function App() {
	useEffect(() => {
		clarity.init(process.env.REACT_APP_MS_CLARITY_ID + '')
	}, [])

	LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY + '')
	const dispatch = useAppDispatch()
	const [mode, setMode] = useState<'light' | 'dark'>('light')

	const handleModeChange = () => {
		setMode((prevMode: PaletteMode) =>
			prevMode === 'light' ? 'dark' : 'light'
		)
	}

	const theme = getThemeByURL(mode)

	const getPartnerCode = () => {
		var partnerCode = ''

		switch (window.location.origin) {
			case process.env.REACT_APP_LUMEN_PORTAL_URL: {
				partnerCode = 'lmn'
				dispatch(setPartner(partnerCode))
				break
			}
			default:
				partnerCode = 'lmn'
				dispatch(setPartner(partnerCode))
				break
		}
	}

	getPartnerCode()

	// MSAL configuration
	const configuration: Configuration = {
		auth: {
			clientId: process.env.REACT_APP_CLIENT_ID + '',
		},
	}

	const pca = new PublicClientApplication(configuration)

	return (
		<>
			<MsalProvider instance={pca}>
				<Box className='App' id='app'>
					<ThemeProvider theme={theme}>
						<Router>
							{/* Header */}
							<Header handleModeChange={handleModeChange} mode={mode} />
							{/* Main Body Box */}
							<Box component='main' bgcolor='background.default' flex={1}>
								{/* Side Menu */}
								{window.location.pathname !== '/a3876' ? <Sidebar /> : null}
								{/* Page content */}
								<Box className='page-content-container'>
									{window.location.pathname !== '/a3876' ? (
										<Breadcrumbs />
									) : null}
									<Box className='page-content'>
										<Routes>
											<Route path='/' element={<Dashboard />} />
											<Route path='/Login' element={<LoginRedirect />} />
											<Route
												path='/a3876'
												element={<AppRegistrationRedirect />}
											/>
											<Route
												path='/dashboard'
												element={
													<ProtectedRoute
														permissionID={Permissions.ViewDashboard}
														component={Dashboard}
													/>
												}
											/>
											<Route
												path='/dashboard/teams-user-management'
												element={
													<TUMProvider>
														<ProtectedRoute
															permissionID={Permissions.ManageTeamsUsers}
															component={TeamsUserManagement}
														/>
													</TUMProvider>
												}
											/>
											<Route
												path='/dashboard/trial'
												element={
													<ProtectedRoute
														permissionID={Permissions.AddOrders}
														component={POCInstances}
													/>
												}
											/>
											<Route
												path='/dashboard/trial/add-trial-order'
												element={
													<ProtectedRoute
														permissionID={Permissions.AddOrders}
														component={AddPOCOrder}
													/>
												}
											/>
											<Route
												path='/dashboard/customer-management'
												element={
													<ProtectedRoute
														permissionID={Permissions.AddCustomer}
														component={CustomerManagement}
													/>
												}
											/>
											<Route
												path='/dashboard/customer-overview/:customerID'
												element={
													<ProtectedRoute
														permissionID={Permissions.AddCustomer}
														component={CustomerOverview}
													/>
												}
											/>
											<Route
												path='/dashboard/tn-management'
												element={
													<ProtectedRoute
														permissionID={Permissions.ViewDDI}
														component={DDIManagement}
													/>
												}
											/>
											<Route
												path='/dashboard/order-management'
												element={
													<ProtectedRoute
														permissionID={Permissions.AddOrders}
														component={OrderManagement}
													/>
												}
											/>
											<Route
												path='/dashboard/pricebook-management'
												element={
													<ProtectedRoute
														permissionID={Permissions.ViewPriceBooks}
														component={PriceBookManagement}
													/>
												}
											/>
											<Route
												path='/knowledge-base'
												element={
													<ProtectedRoute
														permissionID={Permissions.ViewDashboard}
														component={Support}
													/>
												}
											/>
											<Route
												path='/dashboard/add-trunk/:customerID'
												element={
													<ProtectedRoute
														permissionID={Permissions.AddTrunkGroups}
														component={AddTrunk}
													/>
												}
											/>
											<Route
												path='/dashboard/overview/:customerID'
												element={
													<ProtectedRoute
														permissionID={Permissions.ViewCustomer}
														component={CustomerOverview}
													/>
												}
											/>
											<Route
												path='/dashboard/teams-overview/:customerID'
												element={
													<ProtectedRoute
														permissionID={Permissions.ViewCustomer}
														component={TeamsOverview}
													/>
												}
											/>
											<Route
												path='/dashboard/user-management'
												element={
													<ProtectedRoute
														permissionID={Permissions.UpdateUsers}
														component={PortalUserManagement}
													/>
												}
											/>
											{/* Custom Route - TDR Self Service Provisioning */}
											<Route
												path='/gid234879'
												element={<TDRSelfServiceProvisioning />}
											/>
											<Route
												path='/dashboard/leads'
												element={
													<ProtectedRoute
														permissionID={Permissions.AddCustomer}
														component={LeadManagement}
													/>
												}
											/>
											<Route
												path='/CustomerCompliance'
												element={
													<ProtectedRoute
														permissionID={Permissions.ManageTeamsUsers}
														component={AcceptCompliance}
													/>
												}
											/>
											<Route
												path='/AddressManagement'
												element={
													<ProtectedRoute
														permissionID={Permissions.UpdateAddress}
														component={AddressManagement}
													/>
												}
											/>
										</Routes>
									</Box>
								</Box>
							</Box>
							{/* Footer */}
							<Footer />
						</Router>
					</ThemeProvider>
				</Box>
				{
					<ToastContainer
						style={{
							width: '350px',
							paddingLeft: '10px',
						}}
						position='top-right'
						autoClose={6000}
						hideProgressBar={false}
						newestOnTop={true}
						closeOnClick={false}
						rtl={false}
						pauseOnFocusLoss
						draggable={false}
						pauseOnHover
						theme={mode}
					/>
				}
			</MsalProvider>
		</>
	)
}

export default App
