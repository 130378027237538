import { useSelector } from 'react-redux'
import '../../components/distinct/dashboard/OrderManagement/Modals/ConfirmOrder/ConfirmOrder'
import { RootState } from '../../store/store'
import { useEffect, useRef, useState } from 'react'
import {
	Address,
	AddressType,
	Country,
	State,
} from '../../utils/interfaces/DBModels'
import { AddressDisplay } from '../../utils/interfaces/ComponentModels'
import PostAndRetrieveDataHook from '../../utils/customHooks/APICalls/PostAndRetrieveDataHook'

import { useAppDispatch } from '../../store/hooks'
import { setPageTitle } from '../../store/reducers/reducers'
import Preloader from '../../components/shared/loading/preloader/Preloader'
import AddressOverviewDisplay from '../../components/distinct/dashboard/AddressManagement/AddressOverviewDisplay'

const AddressManagement = () => {
	//use this flag to show or hide component
	const showComplianceReducer = useSelector(
		(state: RootState) => state.RootReducer.showComplianceReducer.value
	)

	// Global variables
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	// Hooks
	const { postAndRetrieveDataFromDB } = PostAndRetrieveDataHook()

	const [apiCallsDone, setAPICallsDone] = useState(false)
	const [addressDisplay, setAddressDisplay] = useState([] as AddressDisplay[])
	const [stateList, setStateList] = useState([] as State[])
	const [countryList, setCountryList] = useState([] as Country[])
	const customerID = useRef<string>('')

	// General
	const dispatch = useAppDispatch()

	useEffect(() => {
		// Set page title
		var pageTitle = 'Address Management'
		dispatch(setPageTitle(pageTitle))
		// Set customer ID
		customerID.current = loggedInUser.customerID

		getAddresses()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	/* GET: All the existing addresses for that customer */
	const getAddresses = async () => {
		var dataResponse = await postAndRetrieveDataFromDB(
			'Customer Addresses',
			`Address.AddressMap.Where(AddressMap.CustomerID='${customerID.current}'), AddressType.All(), State.Where(State.IsActive = 'true'), Country.Where(Country.IsActive = 'true') `,
			false,
			true
		)
		if (dataResponse && dataResponse.Obj) {
			var addressList = dataResponse.Obj.AddressList as Address[]
			var addressTypeList = dataResponse.Obj.AddressTypeList as AddressType[]
			var stateList = dataResponse.Obj.StateList as State[]
			var countryList = dataResponse.Obj.CountryList as Country[]
			//if they have existing addresses build display list for summary table
			if (addressList && addressList.length > 0 && addressList !== undefined) {
				var addressDisplay = [] as AddressDisplay[]

				for (var i = 0; i < addressList.length; i++) {
					var addressMapList = addressList[i].AddressMapList

					if (addressMapList) {
						for (var z = 0; z < addressMapList?.length; z++) {
							var addressName = addressMapList[z]?.AddressName

							var addressType = addressTypeList.find(
								(x) => x.AddressTypeID === addressList[i].AddressTypeID
							)?.AddressType

							var stateObj = stateList.find(
								(x) => x.StateID === addressList[i].StateID
							)

							var stateName = stateObj?.StateName

							var countryName = countryList.find(
								(x) => x.CountryID === stateObj?.CountryID
							)?.CountryName

							addressDisplay.push({
								AddressMapID: addressMapList[z].AddressMapID,
								AddressID: addressList[i].AddressID,
								AddressName: addressName,
								AddressType: addressType,
								HouseNumber: addressList[i]?.HouseNumber,
								AddressLine1: addressList[i].AddressLine1,
								AddressLine2: addressList[i].AddressLine2,
								City: addressList[i].City,
								State: stateName,
								Country: countryName,
								PostalCode: addressList[i].PostalCode,
							})

							setAddressDisplay(addressDisplay)
						}
					}
				}

				setAddressDisplay(addressDisplay)
			}

			setStateList(stateList)
			setCountryList(countryList)
		}

		setAPICallsDone(true)
	}

	return showComplianceReducer ? (
		apiCallsDone ? (
			<AddressOverviewDisplay
				addressDisplay={addressDisplay}
				stateList={stateList}
				countryList={countryList}
				getAddresses={getAddresses}
			/>
		) : (
			<Preloader />
		)
	) : (
		<></>
	)
}

export default AddressManagement
