import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import Prechecks from '../../../../utils/customHooks/Prechecks/Prechecks'
import './DashboardCards.scss'
import {
	ServiceManagementStatusCheck,
	SDAStatus,
	TeamsDRServiceValueDisplayOptions,
} from '../../../../utils/enums/enums'
import {
	SDAOrderSKUInfo,
	UserManagementLicenseOverview,
} from '../../../../utils/interfaces/ComponentModels'
import CustomLoaderWithIcon from '../../../shared/loading/customLoaderWithIcon/CustomLoaderWithIcon'
import CancelIcon from '@mui/icons-material/Cancel'
import { useNavigate } from 'react-router'
import VerifiedIcon from '@mui/icons-material/Verified'
import {
	MSTeamsUser,
	Service,
	ServiceManagementStatus,
} from '../../../../utils/interfaces/DBModels'
import TeamsServiceSelection from '../TeamsServiceSelection/TeamsServiceSelection'
import {
	useCheckActiveTeamsDROrderSKUsForCustomerMutation,
	useGetServiceManagementReportAndStatusForCustomerMutation,
	useGetTenantProvisioningConfigMutation,
	useGetSDADDIStatusMutation,
} from '../../../../services/proxyAPIData'
import {
	CustomPostHookObj,
	PostHookObj,
	TeamsUserManagementPrecheckResponse,
	TenantConfigurationInfo,
	UserDDIs,
} from '../../../../utils/interfaces/APIModels'
import {
	GetEmptyAccountInfo,
	getUTCDateTimeNow,
	showErrorToast,
	showSuccessToast,
	timeout,
	toAlphaString,
	toBetaString,
} from '../../../../utils/helperFunctions/helperFunctions'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import SDAProvisioning from '../../../../pages/dashboard/TeamsDR/SDAProvisioning/SDAProvisioning'
import { PublicClientApplication } from '@azure/msal-browser'
import { GraphScopes } from '../../../../utils/constants/constants'
import ErrorLogging from '../../../../utils/customHooks/ErrorLogging'
import PostUpdateDataHook from '../../../../utils/customHooks/APICalls/PostUpdateDataHook'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { useAppDispatch } from '../../../../store/hooks'
import { setConfirmedAccount } from '../../../../store/reducers/reducers'
import PostCustomEndpointHook from '../../../../utils/customHooks/APICalls/PostCustomEndpointHook'
import Preloader from '../../../shared/loading/preloader/Preloader'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

// Build each card dynamically
// Logic for display
// Card 1: SDA and SM - Check for teams service, teams order and valid status, ddis and trunks and service manager
// Card 2: Microsoft License Overview - Check for licenses and get overview
// Card 3: Teams UM - Check for VRP issue
const DashboardCards = ({ customerID }: { customerID: string }) => {
	// Hooks
	const {
		getTeamsServicesAndCustomerServices,
		getSDAOrderSKU,
		getServiceManagementStatusAndReport,
		getUserLicenses,
		getLineURIAndRoutingPlanDetails,
	} = Prechecks(customerID)

	const [getTenantConfigInfo] = useGetTenantProvisioningConfigMutation()

	const [getSDADDIStatus] = useGetSDADDIStatusMutation()

	const [checkForActiveTeamsDROrderSKUs] =
		useCheckActiveTeamsDROrderSKUsForCustomerMutation()

	const { postUpdateDataWithoutRefetch } = PostUpdateDataHook()

	const { addErrorLog } = ErrorLogging()

	const { postCustomEndpoint } = PostCustomEndpointHook()

	const [runSMChecksForCustomer] =
		useGetServiceManagementReportAndStatusForCustomerMutation()

	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	const dispatch = useAppDispatch()

	const { instance } = useMsal()
	const isAuthenticated = useIsAuthenticated()

	// ******************************* Display constants ******************************* //

	// Loading
	const [sdaLoadingTitle, setSDALoadingTitle] = useState('')
	const [sdaLoadingDescription, setSDALoadingDescription] = useState('')
	const [smLoadingTitle, setSMLoadingTitle] = useState('')
	const [smLoadingDescription, setSMLoadingDescription] = useState('')
	const [licenseLoadingTitle, setLicenseLoadingTitle] = useState('')
	const [licenseLoadingDescription, setLicenseLoadingDescription] = useState('')
	const [teamsUMLoadingTitle, setTeamsUMLoadingTitle] = useState('')
	const [teamsUMLoadingDescription, setTeamsUMLoadingDescription] = useState('')

	// Card Display
	const [sdaSMCardDescription, setSDASMCardDescription] = useState('')
	const [teamsUMCardDescription, setTeamsUMCardDescription] = useState('')
	const [usersCount, setUsersCount] = useState(0)
	const [callQueuesCount, setCallQueuesCount] = useState(0)
	const [autoAttendantsCount, setAutoAttendantsCount] = useState(0)
	const [totalEndpointsCount, setTotalEndpointsCount] = useState(0)
	const [licenseOverview, setLicenseOverview] = useState({
		TotalAvailableLicenses: 0,
		TotalPurchasedLicenses: 0,
		TotalUsedLicenses: 0,
	} as UserManagementLicenseOverview)
	const [msTeamsServiceList, setMSTeamsServiceList] = useState(
		[] as MSTeamsUser[]
	)
	const [serviceList, setServiceList] = useState([] as Service[])

	// ******************************* END Display constants ******************************* //

	//  ******************************* Flags  ******************************* //

	const [getTeamsServicesCallMade, setGetTeamsServicesCallMade] =
		useState(false)
	const [prechecksCallMade, setPrechecksCallMade] = useState(false)
	const [
		teamsDRServiceValueDisplayOption,
		setTeamsDRServiceValueDisplayOption,
	] = useState(TeamsDRServiceValueDisplayOptions.PartnerServiceID)
	const [selectedService, setSelectedService] = useState('')
	const [selectedTeamsServiceID, setSelectedTeamsServiceID] = useState('')
	const [msServiceToUse, setMSServiceToUse] = useState({} as MSTeamsUser)

	// Loading
	const [sdaStatus, setSDAStatus] = useState(10)
	const [smStatus, setSMStatus] = useState(0)
	const [licenseStatus, setLicenseStatus] = useState(0)
	const [teamsUMStatus, setTeamsUMStatus] = useState(0)
	const [isSMAppInstalling, setIsSMAppInstalling] = useState(false)

	// Display
	const [readyForSDA, setReadyForSDA] = useState(false)
	const [sdaCompleted, setSDACompleted] = useState(false)
	const [readyForSM, setReadyForSM] = useState(false)
	const [sdaIssue, setSDAIssue] = useState(false)
	const [smFailure, setSMFailure] = useState(false)
	const [isDoingSDA, setIsDoingSDA] = useState(false)
	const [SMToken, setSMToken] = useState('')

	const [sdaOrderSKUInfo, setSDAOrderSKUInfo] = useState({} as SDAOrderSKUInfo)
	const [userDDIsList, setUserDDIsList] = useState([] as UserDDIs[])
	const [isReconfiguringTeamsDR, setIsReconfiguringTeamsDR] = useState(false)

	const [tenantProvisioningConfig, setTenantProvisioningConfig] = useState(
		{} as TenantConfigurationInfo
	)

	//  ******************************* END Flags  ******************************* //

	// General
	const navigate = useNavigate()

	useEffect(() => {
		if (!getTeamsServicesCallMade) {
			setGetTeamsServicesCallMade(true)
			retrieveTeamsServices()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// ******************************* API Calls ******************************* //

	const retrieveTeamsServices = async () => {
		setSDALoadingTitle('Validating Teams Service/s')
		setSDALoadingDescription(
			'Checking if you have a Teams service configuration'
		)
		var customerHasValidTeamsDROrderSKUError = ''

		var customerHasValidTeamsDROrderSKUResp =
			await checkForActiveTeamsDROrderSKUs(toBetaString(customerID + ''))
				.unwrap()
				.catch((error) => {
					customerHasValidTeamsDROrderSKUError = JSON.stringify(error)
				})

		var decryptedcustomerHasValidTeamsDROrderSKUResp = await toAlphaString(
			customerHasValidTeamsDROrderSKUResp?.Content + ''
		)

		var customerHasValidTeamsDROrderSKU = false

		try {
			customerHasValidTeamsDROrderSKU = JSON.parse(
				decryptedcustomerHasValidTeamsDROrderSKUResp
			) as boolean
		} catch (error) {
			await addErrorLog(
				'Lumen Portal Check for Active Teams DR Order SKUs',
				`${JSON.stringify(error)} ${customerHasValidTeamsDROrderSKUError}`
			)
		}

		if (customerHasValidTeamsDROrderSKU) {
			var teamsAndCustomerServices = await getTeamsServicesAndCustomerServices()

			if (teamsAndCustomerServices) {
				if (
					teamsAndCustomerServices?.TeamsServices &&
					teamsAndCustomerServices.TeamsServices.length === 1
				) {
					// Progress and flag
					setSDAStatus(33)
					setPrechecksCallMade(true)
					setSelectedTeamsServiceID(
						teamsAndCustomerServices.TeamsServices[0]?.ServiceID + ''
					)
					await doPrechecks(teamsAndCustomerServices.TeamsServices[0])
				} else {
					if (
						teamsAndCustomerServices?.TeamsServices &&
						teamsAndCustomerServices.TeamsServices.length > 0
					) {
						setMSTeamsServiceList(teamsAndCustomerServices.TeamsServices)

						// Determine which value to show the customer to identify their different Teams services.
						// PartnerServiceID is the first preference, then Domain and lastly, the teams service ID.
						var _usePartnerServiceID = true
						var _useDomain = true

						if (
							teamsAndCustomerServices?.Services &&
							teamsAndCustomerServices.Services.length > 0
						) {
							var customerServices = teamsAndCustomerServices.Services
							setServiceList(customerServices)
							teamsAndCustomerServices?.TeamsServices.forEach((x) => {
								var matchingService = customerServices.find(
									(y) =>
										y.ServiceID?.toLocaleLowerCase().trim() ===
										x.ServiceID?.toLocaleLowerCase().trim()
								)

								if (
									!matchingService?.PartnerServiceReference ||
									(matchingService?.PartnerServiceReference + '').trim()
										.length === 0
								) {
									_usePartnerServiceID = false
								}

								if (
									!x?.DomainName ||
									(x?.DomainName + '').trim().length === 0
								) {
									_useDomain = false
								}
							})
						}

						setTeamsDRServiceValueDisplayOption(
							TeamsDRServiceValueDisplayOptions.PartnerServiceID
						)

						if (!_usePartnerServiceID) {
							if (_useDomain) {
								setTeamsDRServiceValueDisplayOption(
									TeamsDRServiceValueDisplayOptions.DomainName
								)
							} else {
								setTeamsDRServiceValueDisplayOption(
									TeamsDRServiceValueDisplayOptions.ServiceID
								)
							}
						}
					}
				}
			} else {
				setSDAIssue(true)
				setSDASMCardDescription(
					'You do not have a configured MS Teams service yet.'
				)
			}
		} else {
			setSDAIssue(true)
			setSDASMCardDescription('No valid Teams DR order was found.')
		}
	}

	const doPrechecks = async (msTeamsServiceToUse: MSTeamsUser) => {
		setPrechecksCallMade(true)
		setMSServiceToUse(msTeamsServiceToUse)

		// Make all prechecks call and build cards
		// Variables to build
		var singleCardDescription = ''
		var teamsUMCardDescription = ''

		var _userDDIs = [] as UserDDIs[]
		var _tenantConfigInfo = {} as TenantConfigurationInfo
		var _validTeamsDDIsCheck = {} as TeamsUserManagementPrecheckResponse

		// Flags
		var sdaProcessReadyOrCompleted = false
		var sdaProcessCompleted = false
		var hasValidDDIs = false
		var successSMStatus = false
		var hasValidTenantConfig = true
		var hasValidTeamsDDIs = true

		await getTenantConfigInfo(toBetaString(loggedInUser.customerID + ''))
			.unwrap()
			.then(async (tenantConfigDataResponse) => {
				var decryptedTenantConfigResponse = await toAlphaString(
					tenantConfigDataResponse?.Content + ''
				)

				_tenantConfigInfo = JSON.parse(
					decryptedTenantConfigResponse
				) as TenantConfigurationInfo

				if (
					_tenantConfigInfo.Domains &&
					_tenantConfigInfo.Regions &&
					_tenantConfigInfo.Domains?.length > 0 &&
					_tenantConfigInfo.Regions?.length > 0
				) {
					var regionsForSelectedService = _tenantConfigInfo.Regions.filter(
						(x) =>
							(x?.ServiceID + '').toLowerCase().trim() ===
							(msTeamsServiceToUse?.ServiceID + '').toLowerCase().trim()
					)
					var domainsForSelectedService = _tenantConfigInfo.Domains.filter(
						(x) =>
							(x?.ServiceID + '').toLowerCase().trim() ===
							(msTeamsServiceToUse?.ServiceID + '').toLowerCase().trim()
					)

					if (
						regionsForSelectedService.length === 0 ||
						domainsForSelectedService.length === 0
					) {
						hasValidTenantConfig = false
					} else {
						setTenantProvisioningConfig(_tenantConfigInfo)
					}
				} else {
					hasValidTenantConfig = false
				}
			})
			.catch(() => {
				hasValidTenantConfig = false
			})

		var teamsDROrderSKUInfo = await getSDAOrderSKU(
			msTeamsServiceToUse?.ServiceID + ''
		)

		setSDAOrderSKUInfo(teamsDROrderSKUInfo)

		if (teamsDROrderSKUInfo.SDAStatus >= 0 && hasValidTenantConfig) {
			// Check status
			switch (teamsDROrderSKUInfo.SDAStatus) {
				// No Teams Order Found
				case SDAStatus.NoTeamsOrderSKUFound:
					singleCardDescription =
						'You do not have a configured Teams Order yet.'
					setSDAIssue(true)
					break
				// Not ready for SDA
				case SDAStatus.NotReadyForSDA:
					singleCardDescription =
						'Your Teams order is not ready for the Teams Direct Routing process yet. Please try again later.'
					setSDAIssue(true)
					break
				// Ready for SDA
				case SDAStatus.ReadyForSDA:
					sdaProcessReadyOrCompleted = true
					setReadyForSDA(true)
					singleCardDescription =
						'Configure Service Manager and complete the Teams Direct Routing process'
					break
				// SDA completed
				case SDAStatus.SDACompleted:
					sdaProcessReadyOrCompleted = true
					sdaProcessCompleted = true
					break
			}

			// If SDA is done, then make DDIs and Trunk call
			if (sdaProcessReadyOrCompleted) {
				// Progress and flag
				setSDAStatus(66)

				// Call 3: Teams service DDIs
				setSDALoadingTitle('Validating MS Teams TNs')
				setSDALoadingDescription(
					'Checking if your MS Teams Service belong to the same regions as your TNs'
				)
				var validTeamsDDIsCheck = await getSDADDIStatus(
					toBetaString(msTeamsServiceToUse?.ServiceID + '')
				)
					.unwrap()
					.catch(() => {
						hasValidTeamsDDIs = false
					})

				if (hasValidTeamsDDIs) {
					var decryptedTeamsDDIsCheckResponse = await toAlphaString(
						validTeamsDDIsCheck?.Content + ''
					)

					_validTeamsDDIsCheck = JSON.parse(
						decryptedTeamsDDIsCheckResponse
					) as TeamsUserManagementPrecheckResponse
				} else {
					singleCardDescription = 'Unable to Validate MS Teams TNs'
					setSDAIssue(true)
				}

				if (
					hasValidTeamsDDIs &&
					_validTeamsDDIsCheck &&
					_validTeamsDDIsCheck.customerID
				) {
					if (_validTeamsDDIsCheck.allPrechecksPassed) {
						hasValidDDIs = true
						setSDACompleted(sdaProcessCompleted)
					} else {
						singleCardDescription = _validTeamsDDIsCheck?.message + ''
						setSDAIssue(true)
					}

					if (hasValidDDIs) {
						// Progress and flag
						setSDAStatus(100)

						if (sdaProcessCompleted) {
							// Call 4: Service Manager Status and Report
							setSMLoadingTitle('Validating Service Manager')
							setSMLoadingDescription('Checking your service manager status')
							var serviceManagementCheckObj =
								await getServiceManagementStatusAndReport()

							if (
								serviceManagementCheckObj &&
								serviceManagementCheckObj.ServiceManagementStatus >= 0
							) {
								// Check status
								switch (serviceManagementCheckObj.ServiceManagementStatus) {
									// No status
									case ServiceManagementStatusCheck.NoServiceManagementConfigFound:
										singleCardDescription =
											'Configure Service Manager (recommended).'
										setReadyForSM(true)
										break
									// Failed status
									case ServiceManagementStatusCheck.ServiceManagementFailure:
										singleCardDescription =
											'There is an error with your Teams Direct Routing configuration. Please reconfigure.'
										setSMFailure(true)
										break
									// Success status
									case ServiceManagementStatusCheck.ServiceManagementSuccess:
										successSMStatus = true
										setUsersCount(0)
										setCallQueuesCount(0)
										setAutoAttendantsCount(0)
										setTotalEndpointsCount(0)

										var totalCount = 0

										if (
											Number(serviceManagementCheckObj?.SMReport?.HALODRUsers) >
											0
										) {
											setUsersCount(
												Number(serviceManagementCheckObj?.SMReport?.HALODRUsers)
											)
											totalCount += Number(
												serviceManagementCheckObj?.SMReport?.HALODRUsers
											)
										}

										if (
											Number(
												serviceManagementCheckObj?.SMReport
													?.TenantCallQueueCount
											) > 0
										) {
											setCallQueuesCount(
												Number(
													serviceManagementCheckObj?.SMReport
														?.TenantCallQueueCount
												)
											)
											totalCount += Number(
												serviceManagementCheckObj?.SMReport
													?.TenantCallQueueCount
											)
										}

										if (
											Number(
												serviceManagementCheckObj?.SMReport
													?.HALODRAutoAttendants
											) > 0
										) {
											setAutoAttendantsCount(
												Number(
													serviceManagementCheckObj?.SMReport
														?.HALODRAutoAttendants
												)
											)
											totalCount += Number(
												serviceManagementCheckObj?.SMReport
													?.HALODRAutoAttendants
											)
										}

										if (totalCount > 0) {
											setTotalEndpointsCount(totalCount)
										}

										break
								}

								// If there is a success service manager, then only make calls to get teams user management stuff
								if (successSMStatus) {
									teamsUMCardDescription =
										await getTeamsUserManagerAndLicenseOverviewDetails(
											msTeamsServiceToUse,
											_userDDIs,
											_tenantConfigInfo
										)
								}
							}
						}
					}
				}
			}
		} else {
			singleCardDescription = 'Your Teams Service information is invalid.'
			setSDAIssue(true)
		}

		// Set all values)
		setSDASMCardDescription(singleCardDescription)
		setTeamsUMCardDescription(teamsUMCardDescription)
	}

	const getTeamsUserManagerAndLicenseOverviewDetails = async (
		msTeamsServiceToUse: MSTeamsUser,
		userDDIs: UserDDIs[],
		_tenantConfigInfo: TenantConfigurationInfo
	) => {
		var teamsUMCardDescription = ''
		setSMStatus(100)

		// Call 5: User Licenses
		setLicenseLoadingTitle('Validating Teams Licenses')
		setLicenseLoadingDescription(
			'Checking and validating your Microsoft Licenses'
		)
		var licenseOverview = await getUserLicenses(msTeamsServiceToUse)

		if (licenseOverview && licenseOverview.LicenseOverview) {
			setLicenseOverview(licenseOverview.LicenseOverview)
		}
		setLicenseStatus(100)

		// Call 6: User VRP
		setTeamsUMLoadingTitle('Validating Teams Users and Voice Routing Policies')
		setTeamsUMLoadingDescription(
			'Checking your users and their assigned TNs and Voice Routing Policies and validating if all is correct'
		)
		var lineURIOverview = await getLineURIAndRoutingPlanDetails(
			msTeamsServiceToUse,
			userDDIs,
			_tenantConfigInfo
		)

		if (lineURIOverview && lineURIOverview.HasVRPIssue === true) {
			teamsUMCardDescription =
				'There is an issue with your Voice Routing Policies. Please proceed to user management to view.'
		} else {
			teamsUMCardDescription = 'Proceed to Teams User Management'
		}
		setTeamsUMStatus(100)
		return teamsUMCardDescription
	}

	// ******************************* END API Calls ******************************* //

	const confirmMSTeamsService = async (msTeamsServiceID: string) => {
		var msServiceToUse = msTeamsServiceList.find(
			(x) => x.ServiceID === msTeamsServiceID
		)

		if (msServiceToUse) {
			setSelectedTeamsServiceID(msServiceToUse?.ServiceID + '')

			switch (teamsDRServiceValueDisplayOption) {
				case TeamsDRServiceValueDisplayOptions.PartnerServiceID:
					var matchingService = serviceList.find(
						(x) => x?.ServiceID + '' === msServiceToUse?.ServiceID + ''
					)
					if (matchingService) {
						setSelectedService(matchingService?.PartnerServiceReference + '')
					}
					break
				case TeamsDRServiceValueDisplayOptions.DomainName:
					setSelectedService(msServiceToUse?.DomainName + '')
					break
				default:
					setSelectedService(msServiceToUse?.ServiceID + '')
					break
			}

			setSDAStatus(33)
			setPrechecksCallMade(true)
			await doPrechecks(msServiceToUse)
		}
	}

	const resetTeamsServiceSelection = (keepSelectedService?: boolean) => {
		if (!keepSelectedService) {
			setSelectedService('')
		}
		setSDALoadingTitle('Validating Teams Service')
		setSDALoadingDescription(
			'Checking if you have a Teams service configuration'
		)
		setSDAStatus(10)
		setSMStatus(0)
		setPrechecksCallMade(false)
		setReadyForSDA(false)
		setSDACompleted(false)
		setReadyForSM(false)
		setSDAIssue(false)
		setSMFailure(false)
		setSDASMCardDescription('')
	}

	const reloadDashboardAfterSDA = async (isProvisioningSuccess: boolean) => {
		if (isProvisioningSuccess) {
			showSuccessToast(
				'Provisioning Done, Service Manager results can take up to 8 hours to reflect'
			)

			if (isReconfiguringTeamsDR) {
				await resetSMStatus()
			}
		}

		setIsDoingSDA(false)

		resetTeamsServiceSelection(true)
		await doPrechecks(msServiceToUse)
	}

	const installSMAndStartSDAProcess = async (
		_isReconfiguringTeamsDR: boolean
	) => {
		//var smErrorMessage = 'Consent to Service Manager App was unsuccessful.'
		var smErrorMessage = ' This is required to do Teams DR configuration.'

		var msalSMInstance = new PublicClientApplication({
			auth: {
				clientId: process.env.REACT_APP_SM_SETUP_CLIENT_ID + '',
			},
			system: {
				allowRedirectInIframe: true,
			},
		})

		await msalSMInstance.initialize()

		const SMApplogInRequest = {
			scopes: ['Organization.Read.All'],
			prompt: 'select_account',
		}

		await msalSMInstance
			.loginPopup(SMApplogInRequest)
			.then(async (authenticationResult) => {
				var accInfo = authenticationResult.account

				if (accInfo) {
					var _SMtoken = await msalSMInstance.acquireTokenSilent({
						scopes: GraphScopes,
						account: accInfo,
					})

					if ((_SMtoken?.accessToken + '').length < 1) {
						showErrorToast(smErrorMessage)
					} else {
						setSMToken(_SMtoken?.accessToken + '')
					}

					if (!_isReconfiguringTeamsDR) {
						await resetSMStatus()
					}

					await msalSMInstance
						.logoutRedirect({
							onRedirectNavigate: () => {
								// Return false to stop navigation after local logout
								return false
							},
						})
						.then(async () => {
							dispatch(setConfirmedAccount(GetEmptyAccountInfo()))

							if (isAuthenticated) {
								await instance.logoutRedirect({
									onRedirectNavigate: () => {
										// Return false to stop navigation after local logout
										return false
									},
								})
							}
							setIsDoingSDA(true)
							setIsReconfiguringTeamsDR(_isReconfiguringTeamsDR)
						})
				}
			})
			.catch(async (error) => {
				showErrorToast(smErrorMessage)

				if (!(error?.errorMessage + '').includes('User cancelled')) {
					await addErrorLog(
						'Lumen Service manager consent and token generation',
						`Customer ${customerID} SM consent or token generation failure: ${JSON.stringify(
							error
						)}`
					)
				}
			})
	}

	const installSMOnly = async () => {
		setIsSMAppInstalling(true)
		var smErrorMessage = 'Service Manager App Install was unsuccessful.'

		var msalSMInstance = new PublicClientApplication({
			auth: {
				clientId: process.env.REACT_APP_SM_SETUP_CLIENT_ID + '',
			},
			system: {
				allowRedirectInIframe: true,
			},
		})

		await msalSMInstance.initialize()

		const SMApplogInRequest = {
			scopes: ['Organization.Read.All'],
			prompt: 'select_account',
		}

		await msalSMInstance
			.loginPopup(SMApplogInRequest)
			.then(async (authenticationResult) => {
				var accInfo = authenticationResult.account

				if (accInfo) {
					await msalSMInstance
						.logoutRedirect({
							onRedirectNavigate: () => {
								// Return false to stop navigation after local logout
								return false
							},
						})
						.then(async () => {
							var smInstallErrorMessage =
								'Service Manager App install was unsuccessful.'
							var isInstallSuccessful = true

							var msalSMInstallInstance = new PublicClientApplication({
								auth: {
									clientId:
										process.env.REACT_APP_SM_INSTALL_ONLY_CLIENT_ID + '',
								},
								system: {
									allowRedirectInIframe: true,
								},
							})

							await msalSMInstallInstance.initialize()

							const SMAppInstallRequest = {
								scopes: GraphScopes,
								prompt: 'select_account',
							}

							await msalSMInstallInstance
								.loginPopup(SMAppInstallRequest)
								.then(async (authenticationResult) => {
									var accInfo = authenticationResult.account

									if (accInfo) {
										showSuccessToast(
											'Please wait while we verify app installation'
										)
										await timeout(20000)
										var _SMtoken =
											await msalSMInstallInstance.acquireTokenSilent({
												scopes: GraphScopes,
												account: accInfo,
											})

										if ((_SMtoken?.accessToken + '').length < 1) {
											isInstallSuccessful = false
										} else {
											var smInstallToken = _SMtoken?.accessToken + ''

											var teamsAdminServiceManagement = {
												CustomerID: loggedInUser.customerID,
												GraphToken: smInstallToken,
											}

											var customPostHookObj: CustomPostHookObj = {
												Action: 'Add Teams Admin For Service Management',
												RequestURL: 'AddTeamsAdminForServiceManagement',
												RequestObj: teamsAdminServiceManagement,
												ShowSuccessMessage: false,
												ShowErrorMessage: false,
												ErrorMessage: `An error occurred when Adding Teams Admin For Service Management: ${JSON.stringify(
													teamsAdminServiceManagement
												)}`,
												LogErrorToDB: true,
											}

											await postCustomEndpoint(customPostHookObj).catch(() => {
												isInstallSuccessful = false
											})
										}

										await msalSMInstallInstance
											.logoutRedirect({
												onRedirectNavigate: () => {
													// Return false to stop navigation after local logout
													return false
												},
											})
											.then(async () => {
												if (!isInstallSuccessful) {
													showErrorToast(smInstallErrorMessage)
												} else {
													showSuccessToast(
														'Service Manager results can take up to 8 hours to reflect'
													)

													//If SM is being installed for a specific service, then reset SM status in DB

													await resetSMStatus()

													await runSMChecksForCustomer(
														toBetaString(loggedInUser.customerID + '')
													).unwrap()

													setReadyForSM(false)
													setSMStatus(100)
													setSDASMCardDescription('')
													setSMFailure(false)
													setUsersCount(0)
													setCallQueuesCount(0)
													setAutoAttendantsCount(0)
													setTotalEndpointsCount(0)

													var teamsUMCardDescription =
														await getTeamsUserManagerAndLicenseOverviewDetails(
															msServiceToUse,
															userDDIsList,
															tenantProvisioningConfig
														)
													setTeamsUMStatus(100)
													setTeamsUMCardDescription(teamsUMCardDescription)
												}
											})
									}
								})
								.catch(async (error) => {
									showErrorToast(smInstallErrorMessage)

									if (!(error?.errorMessage + '').includes('User cancelled')) {
										await addErrorLog(
											'Lumen Service manager install only and token generation',
											`Customer ${customerID} SM install only or token generation failure: ${JSON.stringify(
												error
											)}`
										)
									}
								})
						})
				}
			})
			.catch(async (error) => {
				showErrorToast(smErrorMessage)

				if (!(error?.errorMessage + '').includes('User cancelled')) {
					await addErrorLog(
						'Lumen Service manager consent only and token generation',
						`Customer ${customerID} SM consent only or token generation failure: ${JSON.stringify(
							error
						)}`
					)
				}
			})

		setIsSMAppInstalling(false)
	}

	const resetSMStatus = async () => {
		var serviceIDToUse = ''
		if (selectedTeamsServiceID.length > 0) {
			serviceIDToUse = selectedTeamsServiceID
		}

		if (serviceIDToUse.length === 0) {
			if (msTeamsServiceList.length > 0) {
				serviceIDToUse = msTeamsServiceList[0]?.ServiceID + ''
			}
		}

		var smStatusToDeleteObj = {
			ServiceManagementStatus: {
				CustomerID: customerID,
				ServiceID: serviceIDToUse,
			},
		}

		var deletePostHookObj: PostHookObj = {
			Action: `Clear Service Management Status`,
			LoggedInUser: loggedInUser,
			RequestURL: 'DeleteV2?Params=ServiceManagementStatus',
			RequestObj: smStatusToDeleteObj,
			ShowSuccessMessage: false,
			ShowErrorMessage: false,
			LogErrorToDB: true,
		}

		await postUpdateDataWithoutRefetch(deletePostHookObj).then(async () => {
			var smStatusToAdd: ServiceManagementStatus = {
				CustomerID: customerID,
				JobID: 'ManualAdd - Lumen Portal Consent',
				JobStatus: 'Success',
				Description: 'ManualAdd - Lumen Portal Consent',
				LogTime: getUTCDateTimeNow(),
				ServiceID: serviceIDToUse,
			}

			var smStatusToAddObj = {
				ServiceManagementStatus: smStatusToAdd,
			}

			var addPostHookObj: PostHookObj = {
				Action: `Add Service Management Status`,
				LoggedInUser: loggedInUser,
				RequestURL: 'AddV2?Params=ServiceManagementStatus',
				RequestObj: smStatusToAddObj,
				ShowSuccessMessage: false,
				ShowErrorMessage: false,
				LogErrorToDB: true,
			}

			await postUpdateDataWithoutRefetch(addPostHookObj)
		})
	}

	return (
		<>
			<Box className='dashboard-card-container'>
				{/* Card 1 - Teams Direct Routing and Service Manager */}
				<Box className='dashboard-card'>
					{/* Header */}
					<Box
						className='dashboard-card-header'
						bgcolor='background.superLight'>
						{/* Image */}
						<img
							src={require('../../../../assets/icons/card/lmn/card-tdr.png')}
							alt='Teams Logo for Teams Direct Routing'
						/>
						{/* Title */}
						<Typography component='h3' color='text.primary'>
							HALO SM - Teams Direct Routing
						</Typography>
					</Box>
					{/* Content */}
					<Box
						className='dashboard-card-content'
						border={2}
						borderColor='border.primary'>
						{/* Allow user to change the teams service they've selected */}
						{selectedService.length > 0 &&
							!isDoingSDA &&
							((readyForSDA && sdaStatus === 100) ||
								(sdaCompleted && sdaStatus === 100) ||
								sdaIssue) && (
								<Tooltip title='Select another MS Teams Service'>
									<Button
										sx={{
											textTransform: 'none',
											marginBottom: '10px',
											padding: '5px',
										}}
										startIcon={
											<ChangeCircleIcon
												sx={{
													color: 'text.primary',
												}}
											/>
										}
										onClick={() => {
											resetTeamsServiceSelection()
										}}>
										{`MS Teams Service: ${selectedService}`}
									</Button>
								</Tooltip>
							)}

						<Box className='dashboard-card-content-flex'>
							{/* SDA */}
							<>
								{/* User must Select a Teams Service to use */}
								{msTeamsServiceList.length > 0 &&
								!prechecksCallMade &&
								!isDoingSDA ? (
									<Box className='dashboard-card-content-col'>
										<Typography component='h4'>
											Select a Teams Service to proceed with:
										</Typography>
										{/* Form */}
										<TeamsServiceSelection
											confirmSelection={confirmMSTeamsService}
											teamsServiceList={msTeamsServiceList}
											serviceList={serviceList}
											teamsDRServiceValueDisplayOption={
												teamsDRServiceValueDisplayOption
											}
										/>
									</Box>
								) : (
									<>
										{/* Teams DR status is loading */}
										{sdaStatus < 100 && !sdaIssue && !sdaCompleted ? (
											<Box className='dashboard-card-content-col'>
												{selectedService.length > 0 && (
													<Typography component='h4'>
														{`MS Teams Service: ${selectedService}`}
													</Typography>
												)}
												<Typography component='h4'>Service Overview</Typography>
												{/* Loader Box */}
												<Box>
													<CustomLoaderWithIcon
														icon={
															<img
																src={require('../../../../assets/icons/card/lmn/card-tdr.png')}
																alt='Teams Icon for Teams Direct Routing'
															/>
														}
														progressValue={sdaStatus}
														error={false}
													/>
													<Typography component='h5'>
														{sdaLoadingTitle}
													</Typography>
													<Typography component='p'>
														{sdaLoadingDescription}
													</Typography>
												</Box>
											</Box>
										) : (
											<>
												{isDoingSDA ? (
													<Box className='dashboard-card-content-col'>
														{/* Show SDA Component*/}
														<SDAProvisioning
															_tenantProvisioningConfig={
																tenantProvisioningConfig
															}
															customerID={customerID}
															isReprovisioning={isReconfiguringTeamsDR}
															sdaOrderSKUInfo={sdaOrderSKUInfo}
															smToken={SMToken}
															teamsServiceID={selectedTeamsServiceID}
															reloadDashboard={reloadDashboardAfterSDA}
														/>
													</Box>
												) : (
													<Box className='dashboard-card-content-col'>
														{/* Show Teams DR status of selected Service*/}
														<Typography component='h4'>
															Service Overview
														</Typography>
														<Box
															sx={{
																display: 'flex',
																justifyContent: 'center',
															}}>
															{sdaIssue ? (
																<CancelIcon color='error' />
															) : !sdaIssue && sdaCompleted && !smFailure ? (
																<Box>
																	<img
																		src={require('../../../../assets/images/dashboard/lumen-teams-img.png')}
																		alt='lumen-teams-configured-img'
																	/>
																</Box>
															) : (!sdaIssue && readyForSDA) || smFailure ? (
																<>
																	<VerifiedIcon
																		sx={{ color: 'text.primary' }}
																	/>
																</>
															) : null}
														</Box>
													</Box>
												)}
											</>
										)}
									</>
								)}
							</>

							{/* Sipcom Tenant Voice Count */}
							{!smFailure && smStatus === 100 && (
								<>
									<Box
										className='dashboard-card-content-col'
										sx={{ marginLeft: '40px', marginTop: '8px' }}>
										<Typography component='h4'>Users</Typography>
										<Typography component='h2' color='text.primary'>
											{`${usersCount}`}
										</Typography>
									</Box>
									<Box
										className='dashboard-card-content-col'
										sx={{ marginTop: '40px' }}>
										<Typography component='h2'>+</Typography>
									</Box>
									<Box
										className='dashboard-card-content-col'
										sx={{ marginTop: '8px' }}>
										<Typography component='h4'>Call Queues</Typography>
										<Typography component='h2' color='text.primary'>
											{`${callQueuesCount}`}
										</Typography>
									</Box>
									<Box
										className='dashboard-card-content-col'
										sx={{ marginTop: '40px' }}>
										<Typography component='h2'>+</Typography>
									</Box>
									<Box
										className='dashboard-card-content-col'
										sx={{ marginTop: '8px' }}>
										<Typography component='h4'>Auto Attendants</Typography>
										<Typography component='h2' color='text.primary'>
											{`${autoAttendantsCount}`}
										</Typography>
									</Box>
									<Box
										className='dashboard-card-content-col'
										sx={{ marginTop: '40px' }}>
										<Typography component='h2'>=</Typography>
									</Box>
									<Box
										className='dashboard-card-content-col'
										sx={{ marginRight: '40px', marginTop: '8px' }}>
										<Typography component='h4'>Total Endpoints</Typography>
										<Typography component='h2' color='text.primary'>
											{`${totalEndpointsCount}`}
										</Typography>
									</Box>
								</>
							)}

							{/* Service Manager */}
							{sdaStatus === 100 && sdaCompleted ? (
								<Box>
									{smStatus < 100 && !smFailure && !readyForSM ? (
										<Box className='dashboard-card-content-col'>
											<Typography component='h4'>
												Service Configuration
											</Typography>
											{/* Loader Box */}
											<Box>
												<CustomLoaderWithIcon
													icon={
														<img
															src={require('../../../../assets/icons/card/lmn/card-tdr.png')}
															alt='Teams Icon for Teams Direct Routing'
														/>
													}
													progressValue={smStatus}
													error={false}
												/>
												<Typography component='h5'>{smLoadingTitle}</Typography>
												<Typography component='p'>
													{smLoadingDescription}
												</Typography>
											</Box>
										</Box>
									) : (
										!isDoingSDA && (
											<Box className='dashboard-card-content-col'>
												<Typography component='h4'>
													Service Configuration
												</Typography>
												<Box sx={{ display: 'flex', justifyContent: 'center' }}>
													{smFailure && !readyForSM ? (
														<Box>
															<img
																src={require('../../../../assets/images/dashboard/lumen-service-config-failure-img.png')}
																alt='lumen-teams-service-configuration-failure-img'
															/>
														</Box>
													) : readyForSM ? (
														<VerifiedIcon sx={{ color: 'text.primary' }} />
													) : (
														<Box>
															<img
																src={require('../../../../assets/images/dashboard/lumen-service-config-success-img.png')}
																alt='lumen-teams-service-configuration-success-img'
															/>
														</Box>
													)}
												</Box>
											</Box>
										)
									)}
								</Box>
							) : null}
						</Box>
						{/* Description */}
						{sdaSMCardDescription.length > 0 && !isDoingSDA ? (
							<Box className='dashboard-card-content-desc'>
								<Typography component='p'>{sdaSMCardDescription}</Typography>
								{/* Button(s) */}
								{/* Ready for SDA */}
								{readyForSDA && !sdaIssue ? (
									<Box className='configure-sda-button'>
										<Box sx={{ marginTop: '-8px', marginBottom: '10px' }}>
											<Tooltip
												title='Please ensure that pop-ups are enabled in your browser before
																	proceeding'>
												<span>
													<IconButton>
														<WarningAmberIcon
															sx={{ color: 'orange', marginRight: '5px' }}
														/>
														<Typography component='p'>
															Browser pop-ups are required
														</Typography>
													</IconButton>
												</span>
											</Tooltip>
										</Box>

										<Button
											onClick={() => {
												installSMAndStartSDAProcess(false)
											}}
											variant='contained'
											sx={{ maxWidth: '320px' }}>
											Configure Teams Direct Routing
										</Button>
									</Box>
								) : null}
								{/* SM Failure */}
								{smFailure ? (
									<Box sx={{ alignItems: 'center' }}>
										<Box sx={{ marginTop: '-8px', marginBottom: '10px' }}>
											<Tooltip
												title='Please ensure that pop-ups are enabled in your browser before
																	proceeding'>
												<span>
													<IconButton>
														<WarningAmberIcon
															sx={{ color: 'orange', marginRight: '5px' }}
														/>
														<Typography component='p'>
															Browser pop-ups are required
														</Typography>
													</IconButton>
												</span>
											</Tooltip>
										</Box>

										<Button
											sx={{ maxWidth: '500px' }}
											onClick={() => {
												installSMAndStartSDAProcess(true)
											}}
											variant='contained'>
											Reconfigure Teams Direct Routing
										</Button>
									</Box>
								) : null}
								{/* SM Configure */}
								{readyForSM && (
									<Box>
										{!isSMAppInstalling ? (
											<Button
												variant='contained'
												onClick={() => {
													installSMOnly()
												}}>
												Configure Service Manager
											</Button>
										) : (
											<Preloader />
										)}
									</Box>
								)}
							</Box>
						) : null}
					</Box>
				</Box>
				<Box className='grid-box two tum-li'>
					{/* Card 2 - Microsoft License Overview */}
					{sdaStatus === 100 && smStatus === 100 && !smFailure && !sdaIssue ? (
						<Box className='dashboard-card'>
							{/* Header */}
							<Box
								className='dashboard-card-header'
								bgcolor='background.superLight'>
								{/* Image */}
								<img
									src={require('../../../../assets/icons/card/lmn/card-lo.png')}
									alt='Microsoft License Overview Icon'
								/>
								{/* Title */}
								<Typography component='h3' color='text.primary'>
									Microsoft License Overview
								</Typography>
							</Box>
							{/* Content */}
							<Box
								className='dashboard-card-content'
								border={2}
								borderColor='border.primary'>
								<Box className='dashboard-card-content-flex'>
									{/* SDA */}
									{licenseStatus < 100 ? (
										<Box className='dashboard-card-content-col'>
											<Typography component='h4'>
												Microsoft License Overview
											</Typography>
											{/* Loader Box */}
											<Box>
												<CustomLoaderWithIcon
													icon={
														<img
															src={require('../../../../assets/icons/card/lmn/card-lo.png')}
															alt='Microsoft License Overview Icon'
														/>
													}
													progressValue={licenseStatus}
													error={false}
												/>
												<Typography component='h5'>
													{licenseLoadingTitle}
												</Typography>
												<Typography component='p'>
													{licenseLoadingDescription}
												</Typography>
											</Box>
										</Box>
									) : (
										// Assigned
										<Box className='dashboard-card-content-flex'>
											<Box className='dashboard-card-content-col'>
												<Typography component='h2' color='text.primary'>
													{licenseOverview.TotalPurchasedLicenses}
												</Typography>
												<Typography component='h4' color='text.primary'>
													Purchased
												</Typography>
											</Box>
											<Box className='dashboard-card-content-col'>
												<Typography component='h2' color='text.primary'>
													{licenseOverview.TotalUsedLicenses}
												</Typography>
												<Typography component='h4' color='text.primary'>
													Assigned
												</Typography>
											</Box>
											<Box className='dashboard-card-content-col'>
												<Typography component='h2' color='text.primary'>
													{licenseOverview.TotalAvailableLicenses}
												</Typography>
												<Typography component='h4' color='text.primary'>
													Available
												</Typography>
											</Box>
										</Box>
									)}
								</Box>
								{/* Description */}
								<Box>
									{sdaSMCardDescription.length > 0 ? (
										<Typography component='p'>
											{sdaSMCardDescription}
										</Typography>
									) : null}
								</Box>
							</Box>
						</Box>
					) : null}
					{/* Card 3 - Teams User Management */}
					{sdaStatus === 100 && smStatus === 100 && !smFailure && !sdaIssue ? (
						<Box className='dashboard-card'>
							{/* Header */}
							<Box
								className='dashboard-card-header'
								bgcolor='background.superLight'>
								{/* Image */}
								<img
									src={require('../../../../assets/icons/card/lmn/card-um.png')}
									alt='Teams User Management Icon'
								/>
								{/* Title */}
								<Typography component='h3' color='text.primary'>
									Teams User Management
								</Typography>
							</Box>
							{/* Content */}
							<Box
								className='dashboard-card-content'
								border={2}
								borderColor='border.primary'>
								<Box className='dashboard-card-content-flex'>
									{/* SDA */}
									{teamsUMStatus < 100 ? (
										<Box className='dashboard-card-content-col'>
											<Typography component='h4'>
												Teams User Management
											</Typography>
											{/* Loader Box */}
											<Box>
												<CustomLoaderWithIcon
													icon={
														<img
															src={require('../../../../assets/icons/card/lmn/card-um.png')}
															alt='Teams User Management Icon'
														/>
													}
													progressValue={teamsUMStatus}
													error={false}
												/>
												<Typography component='h5'>
													{teamsUMLoadingTitle}
												</Typography>
												<Typography component='p'>
													{teamsUMLoadingDescription}
												</Typography>
											</Box>
										</Box>
									) : (
										<Box className='dashboard-card-content-col'>
											<Typography component='p'>
												{teamsUMCardDescription}
											</Typography>
											<Button
												variant='contained'
												onClick={() =>
													navigate('/dashboard/teams-user-management')
												}>
												View
											</Button>
										</Box>
									)}
								</Box>
								{/* Description */}
								<Box>
									{sdaSMCardDescription.length > 0 ? (
										<Typography component='p'>
											{sdaSMCardDescription}
										</Typography>
									) : null}
								</Box>
							</Box>
						</Box>
					) : null}
				</Box>
			</Box>
		</>
	)
}

export default DashboardCards
