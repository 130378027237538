/* eslint-disable no-loop-func */
import {
	Box,
	Button,
	Divider,
	IconButton,
	InputAdornment,
	LinearProgress,
	MenuItem,
	SwipeableDrawer,
	Tooltip,
	Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import {
	Permissions,
	Roles,
	ServiceTypes,
	TrueFalseAny,
} from '../../../utils/enums/enums'
import './DDIManagement.scss'
import PostAndRetrieveDataHook from '../../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import {
	Country,
	Customer,
	ServiceType,
} from '../../../utils/interfaces/DBModels'
import { GridColDef } from '@mui/x-data-grid-pro'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { usePostBetaObjectWithoutRefetchMutation } from '../../../services/proxyAPIData'
import {
	BetaAPIMutation,
	BetaObject,
	FilterRequest,
	DDIPaginationResponse,
	DDIPaginationResponseResult,
	DataResponse,
	PostHookObj,
} from '../../../utils/interfaces/APIModels'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import SearchIcon from '@mui/icons-material/Search'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
	DDIDisplayList,
	IDValueModel,
} from '../../../utils/interfaces/ComponentModels'
import PostUpdateDataHook from '../../../utils/customHooks/APICalls/PostUpdateDataHook'
import DeleteIcon from '@mui/icons-material/Delete'
import {
	showErrorToast,
	toAlphaString,
	toBetaString,
} from '../../../utils/helperFunctions/helperFunctions'
import { StyledSelect } from '../../../components/shared/styledComponents/StyledSelect/StyledSelect'
import { StyledTextBox } from '../../../components/shared/styledComponents/StyledTextBox/StyledTextBox'
import { StyledDataGrid } from '../../../components/shared/datagrid/StyledDataGrid'
import { useAppDispatch } from '../../../store/hooks'
import { setPageTitle } from '../../../store/reducers/reducers'
import { LumenPartnerID } from '../../../utils/constants/constants'
import NoRowsFound from '../../../components/shared/noRecordsFound/NoRowsFound'
import CustomToolbar from '../../../components/shared/datagrid/CustomToolbar'
import CloseIcon from '@mui/icons-material/Close'

function DDIManagement({ hideHeader }: { hideHeader?: boolean }) {
	const pageTitle = useSelector(
		(state: RootState) => state.RootReducer.pageTitleReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const loggedInRoleAndPermissions = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)

	const dispatch = useAppDispatch()

	// Hooks
	const { postAndRetrieveDataFromDB } = PostAndRetrieveDataHook()

	const [postFilterDDIs] = usePostBetaObjectWithoutRefetchMutation()
	const { postUpdateDataWithoutRefetch } = PostUpdateDataHook()

	// Display constants
	const noValueSelectedNumericValue = -999
	const noValueSelectedStringValue = '---'
	const pageSize = 15
	const [IDValueList, setIDValueList] = useState([
		{
			ID: Number(TrueFalseAny.False),
			Value: `${TrueFalseAny[TrueFalseAny.False]}`,
		},
		{
			ID: Number(TrueFalseAny.True),
			Value: `${TrueFalseAny[TrueFalseAny.True]}`,
		},
		{
			ID: Number(TrueFalseAny.Any),
			Value: `${TrueFalseAny[TrueFalseAny.Any]}`,
		},
	] as IDValueModel[])
	const [customerList, setCustomerList] = useState([] as Customer[])
	const [countryList, setCountryList] = useState([] as Country[])
	const [countryFlagURL, setCountryFlagURL] = useState('')
	const [customerID, setCustomerID] = useState(noValueSelectedStringValue)
	const [countryID, setCountryID] = useState(noValueSelectedNumericValue)
	const [ddiValue, setDDIValue] = useState(noValueSelectedStringValue)
	const [currentPageNo, setCurrentPageNo] = useState(1)
	const [maxPageNo, setMaxPageNo] = useState(999)
	const [totalRecords, setTotalRecords] = useState(9990)
	const [ddiDisplayList, setDDIDisplayList] = useState([] as DDIDisplayList[])
	const [isDDIActive, setIsDDIActive] = useState(Number(TrueFalseAny.Any))
	const [isDDIAssigned, setIsDDIAssigned] = useState(Number(TrueFalseAny.Any))
	const [isRangeActive, setIsRangeActive] = useState(Number(TrueFalseAny.Any))
	const [serviceTypeList, setServiceTypeList] = useState([] as ServiceType[])
	const [serviceInValue, setServiceInValue] = useState('')
	const [serviceOutValue, setServiceOutValue] = useState('')
	const [serviceInTypeID, setServiceInTypeID] = useState(
		noValueSelectedNumericValue
	)
	const [serviceOutTypeID, setServiceOutTypeID] = useState(
		noValueSelectedNumericValue
	)

	// Filter flag
	const [openFilterDrawer, setOpenFilterDrawer] = useState(false)

	// Flags
	const [ddiCallMade, setDDICallMade] = useState(false)
	const [loadingData, setLoadingData] = useState(false)
	const [filterDataLoaded, setFilterDataLoaded] = useState(false)
	const [customerIDToFilterBy, setCustomerIDToFilterBy] = useState('')
	const [isPartnerAdmin, setIsPartnerAdmin] = useState(false)

	useEffect(() => {
		//Set Page Title
		if (pageTitle !== 'TN Management') {
			dispatch(setPageTitle('TN Management'))
		}

		var _serviceTypeList = [] as ServiceType[]
		_serviceTypeList.push({
			ServiceTypeID: noValueSelectedNumericValue,
			ServiceTypeName: 'Any',
		})
		for (var st in ServiceTypes) {
			if (!isNaN(Number(st))) {
				_serviceTypeList.push({
					ServiceTypeID: Number(st),
					ServiceTypeName: ServiceTypes[st],
				})
			}
		}
		setServiceTypeList(
			_serviceTypeList.sort((a, b) =>
				a.ServiceTypeName!.localeCompare(b.ServiceTypeName!, undefined, {
					sensitivity: 'base',
				})
			)
		)
		var _customerIDToFilterBy = ''
		var _isPartnerAdmin = false

		if (
			loggedInRoleAndPermissions.find(
				(x) =>
					Number(x.RoleID) === Roles.CustomerAdmin &&
					(loggedInUser?.customerID + '').length > 0
			)
		) {
			setCustomerIDToFilterBy(loggedInUser?.customerID + '')
			_customerIDToFilterBy = loggedInUser?.customerID + ''
			setCustomerID(loggedInUser?.customerID + '')
		}

		if (
			loggedInRoleAndPermissions.find(
				(x) =>
					Number(x.RoleID) === Roles.PartnerAdmin &&
					Number(x.PermissionID) === Permissions.UpdateDDI
			)
		) {
			setIsPartnerAdmin(true)
			_isPartnerAdmin = true
		}

		// Make call to get DDIs
		if (!ddiCallMade) {
			getInitialData(_customerIDToFilterBy, _isPartnerAdmin)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// Flags
		ddiCallMade,
	])

	const deleteDDIRange = async (ddiRangeID: number) => {
		if (ddiRangeID > 0) {
			setLoadingData(true)
			var ObjToDeleteByDDIRangeID = {
				DDIRangeID: ddiRangeID,
			}

			var deleteDDIInfoObj = {
				DDIRangeTrunkMap: ObjToDeleteByDDIRangeID,
				DDI: ObjToDeleteByDDIRangeID,
				DDIRange: ObjToDeleteByDDIRangeID,
			}

			// Post call to delete
			var postHookObj: PostHookObj = {
				Action: 'Delete DDIs',
				LoggedInUser: loggedInUser,
				RequestURL: 'DeleteV2?Params=DDIRangeTrunkMap,DDI,DDIRange',
				RequestObj: deleteDDIInfoObj,
				ShowSuccessMessage: true,
				SuccessMessage: 'Successfully Deleted TN Range',
				ShowErrorMessage: true,
				ErrorMessage: 'An error occurred when deleting TN Range',
				LogErrorToDB: true,
			}

			await postUpdateDataWithoutRefetch(postHookObj).then(async () => {
				setLoadingData(false)
				await handleResetFilters()
			})
		}
	}

	// Columns for DDIs
	const ddiColumns: GridColDef[] = [
		{ field: 'DDI', headerName: 'TN', width: 150, hideable: false },
		{
			field: 'DDIRangeStart',
			headerName: 'Range Start',
			width: 150,
			flex: 1,
		},
		{
			field: 'DDIRangeEnd',
			headerName: 'Range End',
			width: 150,
			flex: 1,
		},
		{
			field: 'IsDDIActive',
			headerName: 'Is TN Active',
			width: 150,
			flex: 1,
			hideable: true,
			type: 'boolean',
		},
		{
			field: 'IsAssigned',
			headerName: 'Is TN Assigned',
			width: 150,
			flex: 1,
			type: 'boolean',
		},
		{
			field: 'Country',
			headerName: 'Country',
			width: 150,
			flex: 1,
			hideable: false,
		},
		{
			field: 'Customer',
			headerName: 'Customer',
			width: 150,
			flex: 1,
			hideable: false,
		},
		{
			field: 'FOCDate',
			headerName: 'FOC Date',
			width: 100,
			flex: 1,
			hideable: true,
		},
		{
			field: 'ServiceIn',
			headerName: 'Service In',
			width: 100,
			flex: 1,
			hideable: true,
		},
		{
			field: 'ServiceInType',
			headerName: 'Service In Type',
			width: 150,
			flex: 1,
			hideable: true,
		},
		{
			field: 'ServiceOut',
			headerName: 'Service Out',
			width: 100,
			flex: 1,
			hideable: true,
		},
		{
			field: 'ServiceOutType',
			headerName: 'Service Out Type',
			width: 150,
			flex: 1,
			hideable: true,
		},
	]

	isPartnerAdmin &&
		ddiColumns.push({
			field: 'actions',
			type: 'actions',
			cellClassName: 'actions',
			headerName: 'Remove Range',
			width: 220,
			editable: false,
			align: 'center',
			hideable: false,
			flex: 1,
			getActions: ({ id }) => {
				return [
					<>
						<Box className='ddi-management-option'>
							<Tooltip title='Remove Range'>
								<IconButton
									onClick={(e: any) => {
										handleDelete(Number(id))
									}}>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
						</Box>
					</>,
				]
			},
		})

	const handleDelete = async (ddiId: number) => {
		var ddiToFind = ddiDisplayList.find((x) => Number(x.ID) === ddiId)

		if (ddiToFind) {
			var confirmDelete = window.confirm(
				`Are you sure you want to delete DID Range: ${ddiToFind?.DDIRangeStart} to ${ddiToFind?.DDIRangeEnd}? Please note that this will affect the customer's service.`
			)
			if (confirmDelete) {
				await deleteDDIRange(Number(ddiToFind?.DDIRangeID))
			}
		}
	}

	// ***************************************************** API Calls ***************************************************** //

	// GET: Retrieve Data for Filters and initial DDI list
	const getInitialData = async (
		_customerIDToFilterBy: string,
		_isPartnerAdmin: boolean
	) => {
		setDDICallMade(true)
		setDDIDisplayList([] as DDIDisplayList[])
		// Loading
		setLoadingData(true)

		var _customerList = [
			{ CustomerID: noValueSelectedStringValue, CustomerName: 'Any' },
		] as Customer[]

		if (!filterDataLoaded) {
			var _countryList = [
				{ CountryID: noValueSelectedNumericValue, CountryName: 'Any' },
			] as Country[]

			// Make call
			var dataResponse = await postAndRetrieveDataFromDB(
				'DDIFilters',
				`Customer.Where(Customer.CustomerPartnerID = '${LumenPartnerID}'),Country.Where(Country.IsActive = 'true'),State.Where(State.IsActive = 'true')`,
				true,
				true
			)
			if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
				_customerList = _customerList.concat(
					dataResponse.Obj?.CustomerList as Customer[]
				)

				_countryList = _countryList.concat(
					dataResponse.Obj?.CountryList as Country[]
				)

				// sort _countryList alphabetically
				_countryList = _countryList.sort((a, b) =>
					a.CountryName!.trim().localeCompare(b.CountryName!.trim())
				)

				// sort _customerList alphabetically
				_customerList = _customerList.sort((a, b) =>
					a.CustomerName!.trim().localeCompare(b.CustomerName!.trim())
				)
				setFilterDataLoaded(true)
			}

			setCustomerList(_customerList)
			setCountryList(_countryList)
		} else {
			_customerList = customerList
			_customerList = _customerList.sort((a, b) =>
				a.CustomerName!.trim().localeCompare(b.CustomerName!.trim())
			)
		}

		handleResetFilterFields()

		setMaxPageNo(0)
		setCurrentPageNo(0)
		setTotalRecords(0)

		var _filters = {} as Record<string, string>
		_filters['CustomerPartnerID'] = `${LumenPartnerID}`

		if (_customerIDToFilterBy.length > 0 && !_isPartnerAdmin) {
			_filters['CustomerID'] = `${_customerIDToFilterBy}`
		}

		//Get initial Data
		var initialDDIPaginationReq: FilterRequest = {
			pageNo: 1,
			pageSize: pageSize,
			filters: _filters,
		}

		var betaObj: BetaObject = {
			Content: toBetaString(JSON.stringify(initialDDIPaginationReq)),
		}

		var betaApiMutation: BetaAPIMutation = {
			BetaObject: betaObj,
			QueryParam: 'FilterDDIs',
		}

		await postFilterDDIs(betaApiMutation)
			.unwrap()
			.then(async (response) => {
				if (response?.Content) {
					var dataResponse = JSON.parse(
						await toAlphaString(response?.Content)
					) as DataResponse

					if (dataResponse?.Obj) {
						var ddiPaginationResponse =
							dataResponse?.Obj as DDIPaginationResponse
						if (
							ddiPaginationResponse?.PaginatedResults &&
							ddiPaginationResponse.PaginatedResults.length > 0
						) {
							var ddiPaginationResponseResults =
								ddiPaginationResponse?.PaginatedResults as DDIPaginationResponseResult[]
							var _ddiDisplayList = [] as DDIDisplayList[]

							ddiPaginationResponseResults.forEach((x) => {
								var _customer = _customerList.find(
									(c) => c.CustomerID === x.CustomerID
								)

								var _customerNameToUse = 'No_Customer'
								if (_customer?.CustomerName !== undefined) {
									_customerNameToUse = _customer?.CustomerName + ''
								}

								var _focDate = ''
								var _serviceInType = ''
								var _serviceOutType = ''
								var _serviceIn = ''
								var _serviceOut = ''

								if (x?.FOCDate) {
									_focDate = new Date(x.FOCDate).toLocaleDateString('en-US')
								}
								if (x?.ServiceTypeInID) {
									_serviceInType = ServiceTypes[x?.ServiceTypeInID]
								}
								if (x?.ServiceTypeOutID) {
									_serviceOutType = ServiceTypes[x?.ServiceTypeOutID]
								}
								if (x?.ServiceInID) {
									_serviceIn = x.ServiceInID
								}
								if (x?.ServiceOutID) {
									_serviceOut = x.ServiceOutID
								}

								var _ddiDisplayListToAdd: DDIDisplayList = {
									ID: Number(x.ID),
									Country: x?.CountryName + '',
									Customer: _customerNameToUse,
									DDI: x?.DDIValue + '',
									DDIRangeEnd: x?.RangeEnd + '',
									DDIRangeStart: x?.RangeStart + '',
									DDIRangeID: Number(x.DDIRangeID),
									IsAssigned: x.isDDIAssigned,
									IsDDIActive: x.isDDIActive,
									ServiceIn: _serviceIn,
									ServiceOut: _serviceOut,
									ServiceInType: _serviceInType,
									ServiceOutType: _serviceOutType,
									FOCDate: _focDate,
								}

								_ddiDisplayList.push(_ddiDisplayListToAdd)
							})

							setDDIDisplayList(_ddiDisplayList)

							setMaxPageNo(
								Math.ceil(
									Number(ddiPaginationResponse?.TotalRecordsFound) / pageSize
								)
							)
							setTotalRecords(Number(ddiPaginationResponse?.TotalRecordsFound))

							if (Number(ddiPaginationResponse?.TotalRecordsFound) === 0) {
								setCurrentPageNo(0)
							}
							setLoadingData(false)
						}
					}
				}
			})
			.catch(() => {
				showErrorToast('Unable to get DDIs')
			})

		// Loading
		setLoadingData(false)
	}

	const handleFilterData = async (pageNumber: number) => {
		// Close drawer
		setOpenFilterDrawer(false)
		setDDIDisplayList([] as DDIDisplayList[])

		// Loading
		setLoadingData(true)

		var _filters = getFilters()

		//Get initial Data
		var ddiPaginationReq: FilterRequest = {
			pageNo: pageNumber,
			pageSize: pageSize,
			filters: _filters,
		}

		var betaObj: BetaObject = {
			Content: toBetaString(JSON.stringify(ddiPaginationReq)),
		}

		var betaApiMutation: BetaAPIMutation = {
			BetaObject: betaObj,
			QueryParam: 'FilterDDIs',
		}

		await postFilterDDIs(betaApiMutation)
			.unwrap()
			.then(async (response) => {
				if (response?.Content) {
					var dataResponse = JSON.parse(
						await toAlphaString(response?.Content)
					) as DataResponse

					if (dataResponse?.Obj) {
						var ddiPaginationResponse =
							dataResponse?.Obj as DDIPaginationResponse
						if (
							ddiPaginationResponse?.PaginatedResults &&
							ddiPaginationResponse.PaginatedResults.length >= 0
						) {
							var ddiPaginationResponseResults =
								ddiPaginationResponse?.PaginatedResults as DDIPaginationResponseResult[]
							var _ddiDisplayList = [] as DDIDisplayList[]

							ddiPaginationResponseResults.forEach((x) => {
								var _customer = customerList.find(
									(c) => c.CustomerID === x.CustomerID
								)

								var _customerNameToUse = 'No_Customer'
								if (_customer?.CustomerName !== undefined) {
									_customerNameToUse = _customer?.CustomerName + ''
								}

								var _focDate = ''
								var _serviceInType = ''
								var _serviceOutType = ''
								var _serviceIn = ''
								var _serviceOut = ''

								if (x?.FOCDate) {
									_focDate = new Date(x.FOCDate).toLocaleDateString('en-US')
								}
								if (x?.ServiceTypeInID) {
									_serviceInType = ServiceTypes[x?.ServiceTypeInID]
								}
								if (x?.ServiceTypeOutID) {
									_serviceOutType = ServiceTypes[x?.ServiceTypeOutID]
								}
								if (x?.ServiceInID) {
									_serviceIn = x.ServiceInID
								}
								if (x?.ServiceOutID) {
									_serviceOut = x.ServiceOutID
								}

								var _ddiDisplayListToAdd: DDIDisplayList = {
									ID: Number(x.ID),
									Country: x?.CountryName + '',
									Customer: _customerNameToUse,
									DDI: x?.DDIValue + '',
									DDIRangeEnd: x?.RangeEnd + '',
									DDIRangeStart: x?.RangeStart + '',
									DDIRangeID: Number(x.DDIRangeID),
									IsAssigned: x.isDDIAssigned,
									IsDDIActive: x.isDDIActive,
									ServiceIn: _serviceIn,
									ServiceOut: _serviceOut,
									ServiceInType: _serviceInType,
									ServiceOutType: _serviceOutType,
									FOCDate: _focDate,
								}

								_ddiDisplayList.push(_ddiDisplayListToAdd)
							})

							setDDIDisplayList(_ddiDisplayList)

							if (pageNumber === 1) {
								setCurrentPageNo(1)
							}

							setMaxPageNo(
								Math.ceil(
									Number(ddiPaginationResponse?.TotalRecordsFound) / pageSize
								)
							)
							setTotalRecords(Number(ddiPaginationResponse?.TotalRecordsFound))

							if (Number(ddiPaginationResponse?.TotalRecordsFound) === 0) {
								setCurrentPageNo(0)
							}
						}
					}
				}
			})
			.catch(() => {
				showErrorToast('Unable to get DDIs')
			})

		// Loading
		setLoadingData(false)
	}

	const handleResetFilterFields = async () => {
		setCustomerID(noValueSelectedStringValue)
		setCountryID(noValueSelectedNumericValue)
		setCountryFlagURL('')
		setIsDDIAssigned(Number(TrueFalseAny.Any))
		setIsDDIActive(Number(TrueFalseAny.Any))
		setIsRangeActive(Number(TrueFalseAny.Any))
		setDDIValue('')
		setServiceInValue('')
		setServiceOutValue('')
		setServiceInTypeID(noValueSelectedNumericValue)
		setServiceOutTypeID(noValueSelectedNumericValue)
	}

	const getFilters = () => {
		var filters = {} as Record<string, string>

		filters['CustomerPartnerID'] = `${LumenPartnerID}`

		if (customerID !== noValueSelectedStringValue) {
			filters['CustomerID'] = `${customerID.replaceAll(' ', '')}`
		}

		if (countryID !== Number(noValueSelectedNumericValue)) {
			filters['CountryID'] = `${countryID}`
		}

		if (isDDIActive !== Number(TrueFalseAny.Any)) {
			if (isDDIActive === Number(TrueFalseAny.True)) {
				filters['IsDDIActive'] = 'true'
			} else {
				filters['IsDDIActive'] = 'false'
			}
		}

		if (isRangeActive !== Number(TrueFalseAny.Any)) {
			if (isRangeActive === Number(TrueFalseAny.True)) {
				filters['IsRangeActive'] = 'true'
			} else {
				filters['IsRangeActive'] = 'false'
			}
		}

		if (isDDIAssigned !== Number(TrueFalseAny.Any)) {
			if (isDDIAssigned === Number(TrueFalseAny.True)) {
				filters['IsDDIAssigned'] = 'true'
			} else {
				filters['IsDDIAssigned'] = 'false'
			}
		}

		if (ddiValue.length > 0) {
			filters['DDI'] = `${ddiValue.replaceAll(' ', '')}`
		}

		if (serviceInValue.length > 0) {
			filters['ServiceInID'] = `${serviceInValue.replaceAll(' ', '')}`
		}

		if (serviceOutValue.length > 0) {
			filters['ServiceOutID'] = `${serviceOutValue.replaceAll(' ', '')}`
		}

		if (serviceInTypeID > 0) {
			filters['ServiceTypeInID'] = `${serviceInTypeID}`
		}

		if (serviceOutTypeID > 0) {
			filters['ServiceTypeOutID'] = `${serviceOutTypeID}`
		}

		//always apply customer filter if customer is logged in
		if (customerIDToFilterBy.length > 0 && !isPartnerAdmin) {
			filters['CustomerID'] = `${customerIDToFilterBy}`
		}

		return filters
	}

	// ***************************************************** END API Calls ***************************************************** //

	// Handle Functions
	const handleResetFilters = () => {
		setDDICallMade(false)
	}

	const handleCustomerIDChange = (e: { target: any }) => {
		setCustomerID(e.target.value)
	}

	const handleServiceTypeInChange = (e: { target: any }) => {
		setServiceInTypeID(e.target.value)
	}

	const handleServiceTypeOutChange = (e: { target: any }) => {
		setServiceOutTypeID(e.target.value)
	}

	const handleCountryIDChange = (e: { target: any }) => {
		var _countryIDToUse = Number(e.target.value)
		var countryToFind = countryList.find(
			(c) => Number(c.CountryID) === _countryIDToUse
		)
		if (countryToFind) {
			setCountryID(_countryIDToUse)
			setCountryFlagURL(countryToFind?.CountryFlagImageURL + '')
		}
	}

	const handleIsDDIActiveChange = (e: { target: any }) => {
		setIsDDIActive(Number(e.target.value))
	}

	const handleIsDDIAssignedChange = (e: { target: any }) => {
		setIsDDIAssigned(Number(e.target.value))
	}

	const handleIsRangeActiveChange = (e: { target: any }) => {
		setIsRangeActive(Number(e.target.value))
	}

	const handleDDIValueChange = (e: { target: any }) => {
		setDDIValue(e.target.value)
	}

	const handleServiceInValueChange = (e: { target: any }) => {
		setServiceInValue(e.target.value)
	}

	const handleServiceOutValueChange = (e: { target: any }) => {
		setServiceOutValue(e.target.value)
	}

	const handlePrevPageClick = async () => {
		if (currentPageNo - 1 >= 0) {
			setCurrentPageNo(currentPageNo - 1)
			await handleFilterData(currentPageNo - 1)
		}
	}

	const handleNextPageClick = async () => {
		if (currentPageNo + 1 <= maxPageNo) {
			setCurrentPageNo(currentPageNo + 1)
			await handleFilterData(currentPageNo + 1)
		}
	}

	// Open drawer
	const toggleDrawer =
		(open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event &&
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
			) {
				return
			}

			setOpenFilterDrawer(open)
		}

	function showFilters() {
		setOpenFilterDrawer(true)
	}

	return (
		<>
			{/* Drawer - Filters */}
			<SwipeableDrawer
				anchor='top'
				open={openFilterDrawer}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}>
				{/* Filters - All DDIs filters grouped */}
				<Box className='view-ddis-filter-container'>
					<Box sx={{ display: 'flex', justifyContent: 'end' }}>
						<IconButton
							onClick={toggleDrawer(false)}
							sx={{ margin: '0px', padding: '0px' }}>
							<CloseIcon sx={{ margin: '0px', padding: '0px' }} />
						</IconButton>
					</Box>
					{/* Main header */}
					<Box>
						<Typography component='h2'>Filters</Typography>
					</Box>
					{/* Sub header */}
					<Box>
						<Typography component='h4'>Refine search by TN filters</Typography>
					</Box>
					{/* DDI specific filters */}
					<Box className='view-ddis-filters m'>
						{/* Is DDI Assigned Dropdown */}
						<Box className='view-ddis-filter'>
							<Typography component='p'>Is TN Assigned:</Typography>
							{IDValueList.length > 0 && (
								<StyledSelect
									fullWidth
									value={isDDIAssigned}
									type='text'
									placeholder='Is TN Assigned'
									onChange={handleIsDDIAssignedChange}
									disabled={loadingData}>
									{IDValueList.map((item, index) => {
										return (
											<MenuItem key={index} value={item.ID}>
												{item.Value + ''}
											</MenuItem>
										)
									})}
								</StyledSelect>
							)}
						</Box>
						{/* Is Range Active Dropdown */}
						<Box className='view-ddis-filter'>
							<Typography component='p'>Is Range Active:</Typography>
							{IDValueList.length > 0 && (
								<StyledSelect
									fullWidth
									value={isRangeActive}
									type='text'
									placeholder='Is Range Active'
									onChange={handleIsRangeActiveChange}
									disabled={loadingData}>
									{IDValueList.map((item, index) => {
										return (
											<MenuItem key={index} value={item.ID}>
												{item.Value + ''}
											</MenuItem>
										)
									})}
								</StyledSelect>
							)}
						</Box>
						{/* Is DDI Active */}
						<Box className='view-ddis-filter'>
							{/* Is DDI Active Dropdown */}
							<Typography component='p'>Is TN Active:</Typography>
							{IDValueList.length > 0 && (
								<StyledSelect
									fullWidth
									value={isDDIActive}
									type='text'
									placeholder='Is TN Active'
									onChange={handleIsDDIActiveChange}
									disabled={loadingData}>
									{IDValueList.map((item, index) => {
										return (
											<MenuItem key={index} value={item.ID}>
												{item.Value + ''}
											</MenuItem>
										)
									})}
								</StyledSelect>
							)}
						</Box>
						<Box className='view-ddis-filter'>
							<Divider>OR</Divider>
							<StyledTextBox
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<SearchIcon />
										</InputAdornment>
									),
								}}
								onWheel={(e) => (e.target as HTMLElement).blur()}
								fullWidth
								type='text'
								placeholder='Search by TN'
								value={ddiValue}
								onChange={handleDDIValueChange}
							/>
						</Box>
					</Box>
					<Divider />
					{/* Sub header */}
					<Box>
						<Typography component='h4'>Refine search by Country</Typography>
					</Box>
					{/* Address specific filters */}
					<Box className='view-ddis-filters m'>
						{/* Country */}
						<Box className='view-ddis-filter'>
							{/* Country Dropdown */}
							<Typography component='p'>Country:</Typography>
							{countryList.length > 0 && (
								<StyledSelect
									fullWidth
									value={countryID}
									type='text'
									placeholder='Country'
									onChange={handleCountryIDChange}
									disabled={loadingData}>
									{countryList.map((item, index) => {
										return (
											<MenuItem key={index} value={item.CountryID}>
												{item.CountryName}
											</MenuItem>
										)
									})}
								</StyledSelect>
							)}
						</Box>
						{/* Image country */}
						<Box className='view-ddis-filter'>
							<span></span>
							<img
								className='country-img'
								src={
									countryFlagURL.length > 0
										? countryFlagURL
										: require('../../../assets/images/placeHolder/flag-placeholder.png')
								}
								alt='countryFlag'
							/>
						</Box>
					</Box>
					<Divider />
					{/* Sub header */}
					<Box>
						<Typography component='h4'>Refine search by Customer</Typography>
					</Box>
					{/* Other filters */}
					<Box className='view-ddis-filters m'>
						{/* Customer */}
						<Box className='view-ddis-filter'>
							{/* Customer Dropdown */}
							<Typography component='p'>Customer:</Typography>
							{customerList.length > 0 && (
								<StyledSelect
									fullWidth
									value={customerID}
									type='text'
									placeholder='Customer'
									onChange={handleCustomerIDChange}
									disabled={loadingData || !isPartnerAdmin}>
									{customerList.map((item, index) => {
										return (
											<MenuItem key={index} value={item.CustomerID}>
												{item.CustomerName}
											</MenuItem>
										)
									})}
								</StyledSelect>
							)}
						</Box>
					</Box>
					<Divider />
					<Box>
						<Typography component='h4'>Refine search by Services</Typography>
					</Box>
					{/* DDI specific filters */}
					<Box className='view-ddis-filters m'>
						{/* ServiceInType Dropdown */}
						<Box className='view-ddis-filter'>
							{/* ServiceInType */}
							<Typography component='p'>Service In Type:</Typography>
							{serviceTypeList.length > 0 && (
								<StyledSelect
									fullWidth
									value={serviceInTypeID}
									type='text'
									placeholder='Service In Type'
									onChange={handleServiceTypeInChange}
									disabled={loadingData}>
									{serviceTypeList.map((item, index) => {
										return (
											<MenuItem key={index} value={item.ServiceTypeID}>
												{item.ServiceTypeName}
											</MenuItem>
										)
									})}
								</StyledSelect>
							)}
						</Box>

						{/* Service In */}
						<Box className='view-ddis-filter'>
							<Typography component='p'>Service In:</Typography>
							<StyledTextBox
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<SearchIcon />
										</InputAdornment>
									),
								}}
								onWheel={(e) => (e.target as HTMLElement).blur()}
								fullWidth
								type='text'
								placeholder='Search by Service In'
								value={serviceInValue}
								onChange={handleServiceInValueChange}
							/>
						</Box>

						<Box className='view-ddis-filter'></Box>

						{/* ServiceOutType Dropdown */}
						<Box className='view-ddis-filter'>
							{/* ServiceOutType */}
							<Typography component='p'>Service Out Type:</Typography>
							{serviceTypeList.length > 0 && (
								<StyledSelect
									fullWidth
									value={serviceOutTypeID}
									type='text'
									placeholder='Service Out Type'
									onChange={handleServiceTypeOutChange}
									disabled={loadingData}>
									{serviceTypeList.map((item, index) => {
										return (
											<MenuItem key={index} value={item.ServiceTypeID}>
												{item.ServiceTypeName}
											</MenuItem>
										)
									})}
								</StyledSelect>
							)}
						</Box>

						{/* Service Out */}
						<Box className='view-ddis-filter'>
							<Typography component='p'>Service Out:</Typography>
							<StyledTextBox
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<SearchIcon />
										</InputAdornment>
									),
								}}
								onWheel={(e) => (e.target as HTMLElement).blur()}
								fullWidth
								type='text'
								placeholder='Search by Service Out'
								value={serviceOutValue}
								onChange={handleServiceOutValueChange}
							/>
						</Box>
					</Box>

					{/* Buttons */}
					<Box className='view-ddis-filter-buttons'>
						{/* Button */}
						<Box className='filter-ddi-button'>
							<Tooltip title='Reset Filters'>
								<span>
									<Button
										disabled={!ddiCallMade || loadingData}
										variant='contained'
										startIcon={<RestartAltIcon />}
										onClick={handleResetFilters}>
										Reset Filters
									</Button>
								</span>
							</Tooltip>
						</Box>
						{/* Button */}
						<Box className='filter-ddi-button'>
							<Tooltip title='Filter TNs'>
								<span>
									<Button
										disabled={!ddiCallMade || loadingData}
										variant='contained'
										startIcon={<FilterAltIcon />}
										onClick={() => {
											handleFilterData(1)
										}}>
										Filter TNs
									</Button>
								</span>
							</Tooltip>
						</Box>
					</Box>
				</Box>
			</SwipeableDrawer>

			{/* Main display container - DDI Table */}
			<Box className='view-ddis-container'>
				{/* Header */}
				{!hideHeader && (
					<Box className='view-ddis-filter-header'>
						<Box className='view-ddis-filter-header-text'>
							<Typography component='h1'>View your TNs here</Typography>
							<Typography component='p'>
								You can filter these results by clicking the button below:
							</Typography>
						</Box>
					</Box>
				)}

				{/* DDI Display */}
				<Box className='view-ddi-display'>
					{/* Handles the filters drawer */}
					<Box className='filter-pagination'>
						<Box></Box>
						{/* Page Details */}
						<Box className='page-info'>
							<Typography component='p'>
								{!loadingData && `Page: ${currentPageNo}/${maxPageNo}`}
							</Typography>
							{!loadingData && <Divider orientation='vertical' flexItem />}
							<Typography component='p'>
								{!loadingData && `Total Records: ${totalRecords}`}
							</Typography>
						</Box>
						{/* Pagination */}
						<Box className='pagination'>
							{/* Button */}
							<Tooltip title='Previous'>
								<span>
									<IconButton
										disabled={
											!ddiCallMade ||
											loadingData ||
											currentPageNo === 1 ||
											currentPageNo === 0
										}
										onClick={handlePrevPageClick}>
										<NavigateBeforeIcon />
									</IconButton>
								</span>
							</Tooltip>
							{/* Button */}
							<Tooltip title='Next'>
								<span>
									<IconButton
										disabled={
											!ddiCallMade || loadingData || currentPageNo === maxPageNo
										}
										onClick={handleNextPageClick}>
										<NavigateNextIcon />
									</IconButton>
								</span>
							</Tooltip>
						</Box>
					</Box>

					{/* DDI Display */}
					<StyledDataGrid
						rows={ddiDisplayList}
						columns={ddiColumns}
						showCellVerticalBorder={true}
						pageSizeOptions={[15]}
						pagination={false}
						getRowId={(row) => row.ID}
						slots={{
							loadingOverlay: LinearProgress,
							noRowsOverlay: NoRowsFound,
							noResultsOverlay: NoRowsFound,
							toolbar: CustomToolbar,
						}}
						slotProps={{
							toolbar: {
								onFilter: showFilters,
								loading: loadingData,
								showFilter: true,
							},
						}}
						initialState={{
							columns: {
								columnVisibilityModel: {
									// Hide initial columns
									IsAssigned: false,
									IsDDIActive: false,
									FOCDate: false,
									ServiceIn: false,
									ServiceOut: false,
								},
							},
						}}
						loading={!ddiCallMade || loadingData}
						hideFooter={true}
						autoHeight={ddiDisplayList.length > 0}
						sx={
							ddiDisplayList.length === 0
								? {
										minHeight: '400px',
										'.MuiDataGrid-virtualScroller': {
											overflow: 'initial!important',
										},
								  }
								: { height: 'auto' }
						}
					/>
				</Box>
			</Box>
		</>
	)
}

export default DDIManagement
